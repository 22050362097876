import { createRouter, createWebHistory } from "vue-router";

import Member from '../views/public/Member.vue';
import Home from '../views/public/Home.vue';
import Present from '../views/public/Present.vue';
import Products from '../views/public/Products.vue';
import Flow from '../views/public/Flow.vue';
import InviteFlow from '../views/public/InviteFlow.vue';
import Music from '../views/public/Music.vue';
import MusicLP from '../views/public/MusicLP.vue';
import MusicQA from '../views/public/MusicQA.vue';

import Signin from '../views/member/Signin.vue';
import Account from '../views/member/Account.vue';
import UpdatePassword from '../views/member/UpdatePassword.vue';
import Seminar from '../views/member/Seminar.vue';
import Radio from '../views/member/Radio.vue';
import ChatLog from '../views/member/ChatLog.vue';
import Chat from '../views/member/Chat.vue';
import Research from '../views/member/Research.vue';

import Admin from '../views/admin/Admin.vue';
// import ChatRegist from '../views/admin/ChatRegist.vue';
// import MemberRegist from '../views/admin/MemberRegist.vue';
// import InformationRegist from '../views/admin/InformationRegist.vue';
// import ChangeRegist from '../views/admin/ChangeRegist.vue';
// import SeminarRegist from '../views/admin/SeminarRegist.vue';
// import RadioRegist from '../views/admin/RadioRegist.vue';
// import MusicRegist from '../views/admin/MusicRegist.vue';

import UserPolicy from '../views/company/UserPolicy.vue';
import PrivacyPolicy from '../views/company/PrivacyPolicy.vue';
import Torihiki from '../views/company/Torihiki.vue';

import Tokuten from '../views/Tokuten.vue';

import Word from '../views/Word.vue';

const routes = [
  { path: '/word', name: 'Word', component: Word },
  // パブリックページ
  // { path: '/', name: 'Home', component: Home_old },
  { path: '/', name: 'Ringo', component: Home },
  { path: '/member', name: 'Member', component: Member },
  { path: '/present', name: 'Present', component: Present },
  { path: '/products', name: 'Products', component: Products },
  { path: '/flow', name: 'Flow', component: Flow },
  { path: '/inviteflow', name: 'InviteFlow', component: InviteFlow },
  { path: '/music', name: 'Music', component: Music },
  { path: '/musicLP', name: 'MusicLP', component: MusicLP },
  { path: '/musicQA', name: 'MusicQA', component: MusicQA },
  // メンバーページ
  { path: '/member/signin', name: 'Signin', component: Signin },
  { path: '/member/updatepw', name: 'UpdatePassword', component: UpdatePassword },
  { path: '/member/account', name: 'Account', component: Account },
  { path: '/member/seminar', name: 'Seminar', component: Seminar },
  { path: '/member/radio', name: 'Radio', component: Radio },
  { path: '/member/chatlog', name: 'ChatLog', component: ChatLog },
  { path: '/member/chat', name: 'Chat', component: Chat },
  { path: '/member/research', name: 'Research', component: Research },
  // 管理者ページ
  { path: '/admin', name: 'Admin', component: Admin },
  // { path: '/admin/chatregist', name: 'ChatRegist', component: ChatRegist },
  // { path: '/admin/memberregist', name: 'MemberRegist', component: MemberRegist },
  // { path: '/admin/informationregist', name: 'InformationRegist', component: InformationRegist },
  // { path: '/admin/changeregist', name: 'ChangeRegist', component: ChangeRegist },
  // { path: '/admin/seminarregist', name: 'SeminarRegist', component: SeminarRegist },
  // { path: '/admin/radioregist', name: 'RadioRegist', component: RadioRegist },
  // { path: '/admin/musicregist', name: 'MusicRegist', component: MusicRegist },
  // 約款系ページ
  { path: '/userpolicy', name: 'UserPolicy', component: UserPolicy },
  { path: '/privacy', name: 'PrivacyPolicy', component: PrivacyPolicy },
  { path: '/torihiki', name: 'Torihiki', component: Torihiki },
  
  { path: '/tokuten', name: 'Tokuten', component: Tokuten },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

export default router