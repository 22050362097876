<template>
	<div id="products-bg">
		<div id="products" class="products">
			<div class="products-contents">
				<div class="caution">
					コンサルはチケット制になります。<br>
					使用期限はありませんが、ひと月に使えるのは2枚までになります。<br>
					※決済ページで配送先住所と表示されますが、実際にはチケットの配送はありません。
				</div>
				<div class="ticket ticket-blue">
					<div class="ticket-title title-blue">お試しチケット</div>
					<div>
						<div class="ticket-content content-blue">お試しで受けてみたい方用<br>料金：13,200円（税込）</div>
						<a class="link link-blue" href="https://buy.stripe.com/3cs29i8I85FR1jy144" target="_blank">購入ページ</a>
					</div>
				</div>
				<div class="ticket ticket-pink">
					<div class="ticket-title title-pink">チケット 1枚</div>
					<div>
						<div class="ticket-content content-pink">チケット1枚分<br>料金：16,500円（税込）</div>
						<div>
							<a class="link link-pink" href="https://buy.stripe.com/cN229ibUk3xJ1jyaEF" target="_blank">購入ページ</a>
						</div>
					</div>
				</div>
				<div class="ticket ticket-pink">
					<div class="ticket-title title-pink">チケット 3枚</div>
					<div>
						<div class="ticket-content content-pink">チケット3枚分<br>料金：42,900円（税込）【1回あたり14,300円】</div>
						<div>
							<a class="link link-pink" href="https://buy.stripe.com/cN2g08aQg3xJaU8dQS" target="_blank">購入ページ</a>
						</div>
					</div>
				</div>
				<div class="ticket ticket-pink">
					<div class="ticket-title title-pink">チケット 6枚</div>
					<div>
						<div class="ticket-content content-pink">チケット6枚分<br>料金：79,200円（税込）【1回あたり13,200円】</div>
						<div>
							<a class="link link-pink" href="https://buy.stripe.com/aEU5lu2jK9W75zOeUX" target="_blank">購入ページ</a>
						</div>
					</div>
				</div>
				<div class="ticket ticket-pink">
					<div class="ticket-title title-pink">チケット 10枚</div>
					<div>
						<div class="ticket-content content-pink">チケット10枚分<br>料金：126,500円（税込）【1回あたり12,650円】</div>
						<div>
							<a class="link link-pink" href="https://buy.stripe.com/5kAg08gaA5FR6DSfZ2" target="_blank">購入ページ</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  name: 'Products',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  beforeCreate() {
    onAuthStateChanged(getAuth(), (user) => {
      if (!user) {
        this.$router.push("/member/signin");
      }
    });
  },
  mounted() {
    console.log("Products");

		document.getElementById("account").style.color = "black";
		document.getElementsByClassName("menu").forEach(x => {
			x.style.color = "black";
		});

		let bg = document.getElementById("products-bg");
		bg.style.width = window.innerWidth;
		bg.style.height = window.innerHeight;

  },
  methods: {
  }
}
</script>

<style scoped>
#products-bg {
	background-image: url("../../assets/site_images/bg/flower-bg.jpg");
	background-size: cover;
}
.products {
  width: 60%;
  margin: 5vw auto 3vw;
  font-size: 1vw;
}
.products-contents {
  margin-top: 3vw;
}
.caution {
  width: 70%;
  margin: 3vw auto 0;
  text-align: left;
}
.ticket {
  width: 50%;
  margin: 2vw auto;
  border-radius: 10px;
}
.ticket-blue {
  border: 1px solid #7aadda;
}
.ticket-pink {
  border: 1px solid #df9e8a;
}
.ticket-title {
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0.6vw 0;
  font-weight: bold;
}
.title-blue {
  background-color: #89c2f4;
}
.title-pink {
  background-color: #ffb49e;
}

.ticket-content {
  margin-top: 0.6vw;
}
.link {
  display: inline-block;
  text-decoration: none;
  color: white;
  padding: 0.8vw 1.8vw;
  margin: 0.4vw 0;
  font-weight: bold;
  border-radius: 6px;
}
.title-blue, .link-blue {
  background-color: #89c2f4;
}
.title-pink, .link-pink {
  background-color: #ffb49e;
}
@media screen and (max-width: 640px) {
	.products-contents {
		margin-top: 15vw;
	}
  .products {
    margin-top: 7vw;
    margin-bottom: 17vw;
    font-size: 0.8rem;
		width: 95%;
  }
  .ticket {
    width: 90%;
    margin: 6vw auto;
  }
  .link {
    padding: 2vw 4vw;
    margin: 1vw 0;
  }
}
</style>
