<template>
  <div id="footer" class="footer">
    <div>
      <router-link class="link" to="/privacy">プライバシーポリシー</router-link>
      /
      <router-link class="link" to="/torihiki">免責事項</router-link>
    </div>
    <div>
      <router-link to="/">Copyright © 2023 リンゴ村 All rights reserved</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Footer',
  props: {},
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
.footer {
  background-color: var(--footer-bg-color);
  height: 2vw;
  bottom: 0;
  width: 100%;
  position: fixed;
  font-size: 0.6vw;
  line-height: 1vw;
  color: var(--footer-color);
  padding: unset;
}
.footer div {
  height: 50%;
}
.footer a {
  color: var(--footer-color);
  text-decoration: none;
}
.link {
  margin: 0 0.4vw;
}
@media screen and (max-width: 640px) {
  .footer {
    height: 10vw;
		font-size: 0.7rem;
    line-height: 5vw;
    position: fixed;
    bottom: 0;
  }
}
</style>
