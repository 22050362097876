<template>
  <div class="admin">
		<span class="title">
			管理者ページ
		</span>
		<div class="tabs is-centered is-toggle">
			<ul>
				<li :class="{'is-active': isActive == 'chat'}" @click="isActive='chat'">
					<a><span>チャットの登録</span></a>
				</li>
				<li :class="{'is-active': isActive == 'member'}" @click="isActive='member'">
					<a><span>村民の登録</span></a>
				</li>
				<li :class="{'is-active': isActive == 'information'}" @click="isActive='information'">
					<a><span>インフォメーションの登録</span></a>
				</li>
				<li :class="{'is-active': isActive == 'change'}" @click="isActive='change'">
					<a><span>変更履歴の登録</span></a>
				</li>
				<li :class="{'is-active': isActive == 'seminar'}" @click="isActive='seminar'">
					<a><span>セミナーの登録</span></a>
				</li>
				<li :class="{'is-active': isActive == 'radio'}" @click="isActive='radio'">
					<a><span>ラジオの登録</span></a>
				</li>
				<li :class="{'is-active': isActive == 'music'}" @click="isActive='music'">
					<a><span>BGMの登録</span></a>
				</li>
				<li :class="{'is-active': isActive == 'musicQA'}" @click="isActive='musicQA'">
					<a><span>BGMのQA編集</span></a>
				</li>
				<li :class="{'is-active': isActive == 'musicSales'}" @click="isActive='musicSales'">
					<a><span>BGMの売上管理</span></a>
				</li>
			</ul>
		</div>
		<ChatRegist v-if="isActive == 'chat'"/>
		<MemberRegist v-if="isActive == 'member'"/>
		<InformationRegist v-if="isActive == 'information'"/>
		<ChangeRegist v-if="isActive == 'change'"/>
		<SeminarRegist v-if="isActive == 'seminar'"/>
		<RadioRegist v-if="isActive == 'radio'"/>
		<MusicRegist v-if="isActive == 'music'"/>
		<MusicQaRegistVue v-if="isActive == 'musicQA'"/>
		<MusicSales v-if="isActive == 'musicSales'"/>
  </div>
</template>

<script>
import ChatRegist from './ChatRegist.vue';
import MemberRegist from './MemberRegist.vue';
import InformationRegist from './InformationRegist.vue';
import ChangeRegist from './ChangeRegist.vue';
import SeminarRegist from './SeminarRegist.vue';
import RadioRegist from './RadioRegist.vue';
import MusicRegist from './MusicRegist.vue';
import MusicSales from './MusicSales.vue';
import MusicQaRegistVue from './MusicQARegist.vue';

export default {
  name: 'Admin',
	components: {
		ChatRegist,
		MemberRegist,
		InformationRegist,
		ChangeRegist,
		SeminarRegist,
		RadioRegist,
		MusicRegist,
		MusicSales,
		MusicQaRegistVue
	},
  props: {
  },
  data() {
    return {
			isActive: 'music'
    }
  },
  mounted() {
    console.log("Admin");
    document.getElementById("footer").style.display = "none";
  },
  unmounted() {
    document.getElementById("footer").style.display = "grid";
  },
  methods: {
  }
}
</script>

<style scoped>
.admin {
  margin: 4vw auto 0;
	width: 100%;
	max-width: 100%;
}
.title {
  font-family: 'Kiwi Maru', cursive;
  font-weight: bold;
  font-size: 1.6rem;
}
.tabs {
	margin-top: 2vw;

}
.music {
	background-color: aquamarine;
}
</style>
