// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBBhYR9JB3qzODUGB3FVtkA9bWdw10LcQs",
  authDomain: "ringo-mura.firebaseapp.com",
  projectId: "ringo-mura",
  storageBucket: "ringo-mura.appspot.com",
  messagingSenderId: "153546119047",
  appId: "1:153546119047:web:aa21a850e8ce73aa6c2522",
  measurementId: "G-2GJ3NP41Z7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;