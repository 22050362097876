<template>
  <div id="musicLP" class="musicLP noto-sans-jp-400">
		<div class="header">
			<span id="title">BGM収益化サービス</span>
			<img src="../../assets/site_images/ringo.png" alt="">
		</div>
		<div @click="gotoqa" class="qa-link">
			QA・お問い合わせ
		</div>
		<div class="description">
			<div class="description-title">
				BGM収益化サービスの概要
			</div>
			<div class="merit">
				<p class="merit-title">
					BGM収益化サービスのメリット
				</p>
				<div class="merit-content">
					<p><b>YouTube shorts<br>再生回数</b></p>
					<p class="merit-content-sisoku">×</p>
					<p><b>BGM収益単価<br>1再生当たり<span style="color: red;">0.02～0.05円</span></b></p>
					<p class="merit-content-sisoku">×</p>
					<p><b><span style="color: red; font-size: 1.6vw;">業界最高</span>分配率</b></p>
					<p class="merit-content-sisoku">=</p>
					<p><b>BGM収益</b></p>
				</div>
			</div>
			<div class="service-tanka">
				<div class="service">
					<div class="service-title">
						サービスの流れ
					</div>
					<div class="flow">
							<div class="flow-content">
									<p>弊社のBGMを使用</p>
									<img class="service-icon" src="../../assets/icons/onpu.svg" alt="BGM Icon">
							</div>
							<div class="arrow">▶</div>
							<div class="flow-content">
									<p>動画投稿</p>
									<img class="service-icon" src="../../assets/icons/youtube.svg" alt="YouTube Shorts Icon">
							</div>
							<div class="arrow">▶</div>
							<div class="flow-content">
									<p>お振込み</p>
									<img class="service-icon" src="../../assets/icons/yen.svg" alt="Payment Icon">
							</div>
					</div>
				</div>
				<div class="tanka">
					<div class="tanka-title">
						BGM収益の単価
					</div>
					<div class="tanka-description">
						<div class="tanka-description-title">
							■ 広告収益単価:
						</div>
						<div class="tanka-description-content">
							1再生当たり<span>0.003～0.01</span>円
						</div>
						<div class="tanka-description-title">
							■ BGM収益単価:
						</div>
						<div class="tanka-description-content">
							1再生当たり<span>0.02～0.05</span>円
						</div>
					</div>
					<div class="tanka-warn">
						※BGM収益額の例<br>
						<span class="indent">月100万回再生＝2万円～（＋アドセンス収益）</span><br>
						<span class="indent">月500万回再生＝10万円～（＋アドセンス収益）</span>
					</div>
				</div>
			</div>
		</div>
		<div class="howtouse">
			<div class="howtouse-title">
				サービスの流れ
			</div>
			<div class="yajirusi">
				最短1週間～
			</div>
			<div class="howtouse-flow">
				<div class="howtouse-content">
					<p>面談・打合せ</p>
					<img src="../../assets/icons/akushu.svg" alt="Contract Icon">
				</div>
				<div class="arrow">▶</div>
				<div class="howtouse-content">
					<p>楽曲作成</p>
					<img src="../../assets/icons/onpu.svg" alt="Music Creation Icon">
				</div>
				<div class="arrow">▶</div>
				<div class="howtouse-content">
					<p>投稿開始</p>
					<img src="../../assets/icons/youtube.svg" alt="YouTube Shorts Icon">
				</div>
				<div class="arrow">▶</div>
				<div class="howtouse-content highlight">
					<p>収益確定</p>
				</div>
				<div class="arrow">▶</div>
				<div class="howtouse-content">
					<p>お振込み</p>
					<img src="../../assets/icons/yen.svg" alt="Payment Icon">
				</div>
			</div>
		</div>
		<div class="features">
			<div class="features-title">
				BGM収益化サービスの特徴
			</div>
			<div class="features-contents">
				<div class="features-content">
					<div class="features-num">1</div>
					<div class="features-heading">
						完全無料で運用開始
					</div>
					<div class="features-description">
						サービスの開始から収益獲得まで、<br>登録料や会費などは一切不要で、<br>運用開始いただけます。<br><br><span class="features-description-sub">※サービスの説明および、要望の聞き取りの為、<br>初回はオンライン打合せを実施させていただきます。</span>
					</div>
				</div>
				<div class="features-content">
					<div class="features-num">2</div>
					<div class="features-heading">
						アドセンス収益と併用し<br>BGM収益を獲得可能
					</div>
					<div class="features-description">
						Shorts動画のアドセンス単価が<br>0.003～0.01円と言われている中、<br>このサービスを運用するだけで大幅な<br>収益拡大が可能です。<br><br><b>Shorts動画だけでも<br>収益獲得が充分可能です。</b>
					</div>
					<div class="features-description-sub">
						※収益単価はクリエイター様によって異なります<br>※Google社の影響で単価や計算方法は変動します
					</div>
				</div>
				<div class="features-content">
					<div class="features-num">3</div>
					<div class="features-heading">
						収益分配の透明化
					</div>
					<div class="features-description">
						弊社ではクリエイター様への収益分配率を<br>業界最高分配率としております。<br><br>
						当社サービスでは、書面にて<br>収益の内訳を明確化し、透明性を確保します。
					</div>
					<div class="features-description-sub">
					</div>
				</div>
			</div>
		</div>
		<div class="compensation">
			<div class="compensation-title">
				報酬体系
			</div>
			<div class="compensation-title-sub">
				投稿月からお支払いまで2か月の期間がございます
			</div>
			<div class="compensation-table">
				<table>
					<tr>
						<td></td>
						<td class="compensation-month">1月</td>
						<td class="compensation-month">2月</td>
						<td class="compensation-month">3月</td>
						<td class="compensation-month">4月</td>
						<td class="compensation-month">5月</td>
						<td class="compensation-month">6月</td>
						<td class="compensation-month">7月</td>
						<td class="compensation-month">8月</td>
						<td class="compensation-month">9月</td>
						<td class="compensation-month">10月</td>
						<td class="compensation-month">11月</td>
						<td class="compensation-month">12月</td>
					</tr>
					<tr>
						<td><div class="compensation-toukou">投稿</div></td>
						<td><div class="compensation-toukou-num">①</div></td>
						<td><div class="compensation-toukou-num">②</div></td>
						<td><div class="compensation-toukou-num">③</div></td>
						<td><div class="compensation-toukou-num">④</div></td>
						<td><div class="compensation-toukou-num">⑤</div></td>
						<td><div class="compensation-toukou-num">⑥</div></td>
						<td><div class="compensation-toukou-num">⑦</div></td>
						<td><div class="compensation-toukou-num">⑧</div></td>
						<td><div class="compensation-toukou-num">⑨</div></td>
						<td><div class="compensation-toukou-num">⑩</div></td>
						<td><div class="compensation-toukou-num">⑪</div></td>
						<td><div class="compensation-toukou-num">⑫</div></td>
					</tr>
					<tr>
						<td><div class="compensation-meisai">明細書送付<br>（中旬頃）</div></td>
						<td></td>
						<td></td>
						<td><div class="compensation-meisai-num">①</div></td>
						<td><div class="compensation-meisai-num">②</div></td>
						<td><div class="compensation-meisai-num">③</div></td>
						<td><div class="compensation-meisai-num">④</div></td>
						<td><div class="compensation-meisai-num">⑤</div></td>
						<td><div class="compensation-meisai-num">⑥</div></td>
						<td><div class="compensation-meisai-num">⑦</div></td>
						<td><div class="compensation-meisai-num">⑧</div></td>
						<td><div class="compensation-meisai-num">⑨</div></td>
						<td><div class="compensation-meisai-num">⑩</div></td>
					</tr>
					<tr>
						<td><div class="compensation-siharai">お支払い<br>（月末）</div></td>
						<td></td>
						<td></td>
						<td><div class="compensation-siharai-num">①</div></td>
						<td><div class="compensation-siharai-num">②</div></td>
						<td><div class="compensation-siharai-num">③</div></td>
						<td><div class="compensation-siharai-num">④</div></td>
						<td><div class="compensation-siharai-num">⑤</div></td>
						<td><div class="compensation-siharai-num">⑥</div></td>
						<td><div class="compensation-siharai-num">⑦</div></td>
						<td><div class="compensation-siharai-num">⑧</div></td>
						<td><div class="compensation-siharai-num">⑨</div></td>
						<td><div class="compensation-siharai-num">⑩</div></td>
					</tr>
				</table>
			</div>
		</div>
		<div class="revenue">
			<div class="revenue-title">
				収益パターンの拡大
			</div>
			<div class="revenue-description">
				ライセンスをお持ちの楽曲を、第三者が使用する事により<br><span style="color: red;">収益の拡大</span>が見込めます
			</div>
			<div class="revenue-pattern">
				<table>
					<tr>
						<td rowspan="2">
							<img class="revenue-onpu" src="../../assets/icons/onpu.svg" alt="Music Creation Icon">
						</td>
						<td>
							<img class="arrow-migiue" src="../../assets/icons/arrow_right.svg" alt="arrow">
						</td>
						<td>
							<div class="revenue-human">
								<img src="../../assets/icons/person.svg" alt="">
								<p>ご自身でのBGM収益</p>
							</div>
						</td>
						<td>
							<img class="arrow-migisita" src="../../assets/icons/arrow_right.svg" alt="arrow">
						</td>
						<td rowspan="2">
							<img src="../../assets/icons/yen.svg" alt="Music Creation Icon">
						</td>
					</tr>
					<tr>
						<td>
							<img class="arrow-migisita" src="../../assets/icons/arrow_right.svg" alt="arrow">
						</td>
						<td>
							<div class="revenue-human">
								<img src="../../assets/icons/many_people.svg" alt="">
								<p>第三者が使用し、<br>再生された際のBGM収益を獲得</p>
							</div>
						</td>
						<td>
							<img class="arrow-migiue" src="../../assets/icons/arrow_right.svg" alt="arrow">
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div class="mousikomi" @click="mousikomi">
			<span>お申し込みはこちらから<br>（GoogleFormが起動します）</span>
		</div>
  </div>
</template>

<script>

export default {
  name: 'MusicLP',
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log("MusicLP");
    document.getElementById("header").style.display = "none";
  },
  methods: {
		mousikomi() {
			const url = "https://docs.google.com/forms/d/e/1FAIpQLSdMqeLOTI8g3OeMwNUYdLpgf145qVsvQkfX9nwE-1etGt-jrQ/viewform";
			window.open(url, '_blank');
		},
		gotoqa() {
			const url = "/musicQA";
			window.open(url, '_blank');
		}
  }
}
</script>

<style scoped>
.main-contents {
	background-color: #fdf5eb;
}
.musicLP {
  width: 80%;
  margin: 1vw auto 4vw;
	background-color: #fdf5eb;
}
.bg {
	margin-top: 2vw;
}
.bg img {
	position: relative;
	z-index: -1;
	height: 20vw;
	width: 100%;
	object-fit: cover;
}
.header {
	display: inline-grid;
	font-family: "RocknRoll One", sans-serif;
  font-weight: 400;
  font-style: normal;
	font-size: 2.5rem;
}
.header img {
	width: 4vw;
	height: auto;
	margin: 0 auto;
}
.description {
	width: 90%;
	text-align: left;
	border: 1px solid black;
	margin: 0 auto;
	border-radius: 10px;
	padding: 1vw;
}
.description-title {
	font-size: 2rem;
	font-weight: bold;
	width: 100%;
	height: 4rem;
}
.merit {
	background-color: #fbd7d7;
	width: 80%;
	margin: 0 auto;
	padding: 1vw;
	border-radius: 10px;
}
.merit-title {
	font-weight: bold;
	color: rgb(0, 0, 0);
	text-align: center;
	font-size: 1.6rem;
}
.merit-content {
	margin: 1.6rem auto;
	line-height: 1.6vw;
	display: flex;
	text-align: center;
  justify-content: center;
  align-items: center;
	font-size: 1vw;
}
.merit-content-sisoku {
	font-size: 2vw;
	color: blue;
	font-weight: 900;
}
.merit-content p {
	margin: 0 1vw;	
}
.indent {
	padding-left: 1vw;
}
.flow {
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.flow-content {
	background-color: #fbd7d7;
	padding: 7px;
	border-radius: 10px;
	width: 229px;
	text-align: center;
	height: 10rem;
}
.service-tanka {
	width: 100%;
	display: flex;
	justify-content: space-around;
	margin-top:2rem;
}
.service {
	width: 40%;
	border: 1px solid black;
	padding: 1vw;
	height: 15vw;
	border-radius: 10px;
}
.service-title, .tanka-title {
	text-align: center;
	font-weight: bold;
	font-size: 1.4rem;
	margin-bottom: 2rem;
}
.service-sub-contents {
	display: inline-flex;
}
.service-sub {
	background-color: rgb(236, 194, 236);
}
.service-icon {
	width: 3rem;
	height: auto;
	background-color: azure;
	padding: 10px;
	border-radius: 50%;
	margin-top: 1.2vw;
}
.arrow {
	font-size: 24px;
	color: #117145;
}
.tanka {
	width: 50%;
	background-color: #fbd7d7;
	border-radius: 10px;
	padding: 1vw;
}
.tanka-description {
	margin-left: 5vw;
}
.tanka-description-title {
	font-weight: bold;
}
.tanka-description-content {
	padding-left: 2vw;
}
.tanka-description-content span {
	font-weight: bold;
	font-size: 2vw;
}
.tanka-warn {
	margin-top: 0.6vw;
	margin-left: 1vw;
}
.mousikomi {
	background-color: #FF6B6B;
	color: white;
	width: 40%;
	margin: 1vw auto 2vw;
	padding: 2vw;
	border-radius: 10px;
	font-size: 1.8rem;
	cursor: pointer;
}
.howtouse {
	width: 90%;
	text-align: left;
	border: 1px solid black;
	margin: 2vw auto 0;
	border-radius: 10px;
	padding: 1vw;
}
.howtouse-title {
	font-size: 2rem;
	font-weight: bold;
	width: 100%;
	height: 4rem;
}
.howtouse-flow {
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.howtouse-content {
	background-color: #fbd7d7;
	padding: 7px;
	border-radius: 10px;
	width: 229px;
	text-align: center;
	height: 20rem;
}
.howtouse-flow .highlight {
	background-color: #117145;
	color: white;
}
.highlight p {
	margin-top: 7vw !important;
	font-weight: bold;
}
.howtouse-flow img {
	width: 4rem;
	height: auto;
	background-color: azure;
	padding: 10px;
	border-radius: 50%;
	margin-top: 3.2vw;
}
.howtouse-flow p {
	margin: 10px 0;
	font-size: 16px;
	font-weight: bold;
}
.howtouse-flow .highlight p {
	color: white;
}
.howtouse-arrow {
	font-size: 24px;
	color: #6e2c91;
}
.yajirusi {
	position: relative;
	background-color: #117145;
	margin: 1vw 0;
	width: 58%;
	color: white;
	height: 40px;
	text-indent: 20px;
}
.yajirusi::after {
  content: "";
  position: absolute;
  right: -40px;
  border-left: 20px solid #117145;
  border-top: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.qa-link {
	text-align: right;
	line-height: 2vw;
	margin-right: 5vw;
	cursor: pointer;
}
.features {
	width: 90%;
	text-align: left;
	border: 1px solid black;
	margin: 2vw auto 0;
	border-radius: 10px;
	padding: 1vw;
}
.features-title {
	font-size: 2rem;
	font-weight: bold;
	width: 100%;
	height: 4rem;
}
.features-contents {
	display: flex;
	justify-content: space-around;
	align-items: center;
}
.features-content {
	position: relative;
	background-color: #fbd7d7;
	padding: 7px;
	border-radius: 10px;
	width: 30%;
	height: 22vw;
	text-align: center;
}
.features-num {
	position: absolute;
	color: white;
	background-color: #117145;
	width: 2vw;
	height: 2vw;
	vertical-align: middle;
	border-radius: 50%;
	top: -19px;
	left: -18px;
	line-height: 1.8vw;
	font-weight: bold;
	font-size: 1vw;
}
.features-heading {
	font-weight: bold;
	font-size: 1.2vw;
	margin-top: 1vw;
	height: 5vw;
}
.features-description {
	font-size: 1rem;
	line-height: 1.6rem;
	height: 11vw;
}
.features-description-sub {
	font-size: 0.8rem;
	line-height: 1.6rem;
}
.compensation {
	width: 90%;
	text-align: left;
	border: 1px solid black;
	margin: 2vw auto 0;
	border-radius: 10px;
	padding: 1vw;
}
.compensation table {
	margin: 2vw auto;
}
.compensation-title {
	font-size: 2rem;
	font-weight: bold;
	width: 100%;
	height: 4rem;
}
.compensation-title-sub {
	font-size: 1rem;
}
.compensation-table td {
	border-right: 2px dotted #dfdfdf;
}
.compensation-month {
	text-align: center;
	line-height: 2vw;
	font-weight: bold;
}
.compensation-toukou {
	background-color: #6d86ff;
	width: 8vw;
	height: 6vw;
	margin: 1vw;
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	font-weight: bold;
	color: white;
	border-radius: 10px;
}
.compensation-toukou-num {
	background-color: #6d86ff;
	width: 2vw;
	height: 6vw;
	margin: 1vw 0.5vw;
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	font-weight: bold;
	color: white;
	border-radius: 10px;
}
.compensation-meisai {
	background-color: #ffa463;
	width: 8vw;
	height: 6vw;
	margin: 1vw;
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	font-weight: bold;
	color: white;
	border-radius: 10px;
}
.compensation-meisai-num {
	background-color: #ffa463;
	width: 2vw;
	height: 6vw;
	margin: 1vw 0.5vw;
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	font-weight: bold;
	color: white;
	border-radius: 10px;
}
.compensation-siharai {
	background-color: #ffbebe;
	width: 8vw;
	height: 6vw;
	margin: 1vw;
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	font-weight: bold;
	color: white;
	border-radius: 10px;
}
.compensation-siharai-num {
	background-color: #ffbebe;
	width: 2vw;
	height: 6vw;
	margin: 1vw 0.5vw;
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	font-weight: bold;
	color: white;
	border-radius: 10px;
}
.revenue {
	width: 90%;
	text-align: left;
	border: 1px solid black;
	margin: 2vw auto 0;
	border-radius: 10px;
	padding: 1vw;
}
.revenue-title {
	font-size: 2rem;
	font-weight: bold;
	width: 100%;
	height: 4rem;
}
.revenue-description {
	background-color: #fbd7d7;
	width: 80%;
	margin: 0 auto;
	padding: 1vw;
	border-radius: 10px;
	text-align: center;
	font-size: 1.4vw;
	font-weight: bold;
}
.revenue-pattern {
	display: flex;
}
.revenue-pattern img {
	width: 3vw;
}
.revenue table {
	margin: 2vw auto;
}
.revenue table td {
	vertical-align: middle;
	text-align: center;
	min-width: 5vw;
}
.arrow-migiue {
	width: 2vw !important;
	rotate: -45deg;
}
.arrow-migisita {
	width: 2vw !important;
	rotate: 45deg;
}
.revenue-onpu {
}
.revenue-human {
	height: 8vw;
	background-color: #fdf3d5;
	margin: 1vw 0;
	padding: 0 0.5vw;
	border-radius: 10px;
}
.revenue-human p {
	font-size: 1.2vw;
}
@media screen and (max-width: 640px) {
}
</style>
