<template>
  <div class="updatepassword">
    <table>
      <tr>
        <th>古いパスワード：</th>
        <td><input type="password" id="oldpassword" placeholder="古いパスワード"></td>
      </tr>
      <tr>
        <th>新しいパスワード：</th>
        <td><input type="password" id="newpassword" placeholder="新しいパスワード"></td>
      </tr>
      <tr>
        <th>（確認）新しいパスワード：</th>
        <td><input type="password" id="newpassword-re" placeholder="新しいパスワード"></td>
      </tr>
      <tr>
        <td colspan="2">
          <button class="singin-btn" @click="updatePw">更新</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { EmailAuthProvider, getAuth, reauthenticateWithCredential, updatePassword } from "@firebase/auth";

export default {
  name: 'UpdatePassword',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log("UpdatePassword");

    document.getElementsByClassName("account-menu")[0].style.display = "none";
  },
  methods: {
    async updatePw() {
      const oldpassword = document.getElementById("oldpassword").value;
      const newpassword = document.getElementById("newpassword").value;
      const newpasswordRe = document.getElementById("newpassword-re").value;

      if (newpassword != newpasswordRe) {
        alert("新しいパスワードが確認と一致しません");
        return;
      }

      const auth = getAuth();
      const user = auth.currentUser;
      const cred = EmailAuthProvider.credential(user.email, oldpassword)

      reauthenticateWithCredential(user, cred).then(() => {
        updatePassword(auth.currentUser, newpassword).then(() => {
          alert("ok");
        }).catch((error) => {console.log(error)});
      }).catch((error) => {console.log(error)});
    }
  }
}
</script>

<style scoped>
.updatepassword {
  margin: 0 auto;
}
table {
  margin-top: 2vw;
}
th {
  font-family: 'Kiwi Maru', cursive;
  text-align: right;
  height: 4vw;
}
input {
  height: 1.4vw;
  width: 16vw;
}
button {
  font-family: 'Kiwi Maru', cursive;
  width: 6vw;
  height: 1.8vw;
  font-size: 0.8vw;
  border-radius: 10px;
  border: none;
  background-color: rgb(253, 219, 225);
}
@media screen and (max-width: 640px) {
  .updatepassword {
    width: 100%;
  }
  table {
    margin: 20vw auto;
    width: 60%;
  }
  th {
    display: none;
  }
  input {
    height: 10vw;
    width: 100%;
    font-size: 3vw;
  }
  button {
    font-family: initial;
    margin-top: 2vw;
    width: 20vw;
    height: 10vw;
    font-size: 5vw;
    border-radius: 10px;
    border: none;
    background-color: rgb(253, 219, 225);
  }
}
</style>
