<template>
  <div class="chat-log">
    <MemberHeader/>
    <div v-show="searchShow" class="search-div">
      <div class="search-panel">
        <div @click="toggleSearch" class="search-hide">
          <ion-icon class="search-panel-close" name="close-outline"></ion-icon>
        </div>
        <div class="search-input">
          <div>
            <label for="search-speaker">発言者：</label>
            <select class="search-speaker" v-model="searchSpeaker" name="search-speaker">
              <option value=""></option>
              <option v-for="(value, key) in searchSpeakers" :key="key" :value="value">
                {{ value }}
              </option>
            </select>
          </div>
          <div>
            <input type="text" v-model="searchText" class="search-text" placeholder="検索条件（必須）">
            <button @click="search" class="search-btn">検索</button>
          </div>
        </div>
        <div class="search-result">
          <div v-for="(value, key) in searchResult" :key="key" class="search-result-unit" @click="choiceSearchResult(value.id)">
            <span class="comment-value">{{ value.comment }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-log-contents">
      <div class="list-titles">
        <div>
          <ion-icon @click="toggleSearch" class="chat-search" name="search-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1625065200, 1627743599)">
          <span>2021年7月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1627743600, 1630421999)">
          <span>2021年8月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1630422000, 1633013999)">
          <span>2021年9月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1633014000, 1635692399)">
          <span>2021年10月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1635692400, 1638284399)">
          <span>2021年11月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1638284400, 1640962799)">
          <span>2021年12月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1640962800, 1643641199)">
          <span>2022年1月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1643641200, 1646060399)">
          <span>2022年2月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1646060400, 1648738799)">
          <span>2022年3月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1648738800, 1651330799)">
          <span>2022年4月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1651330800, 1654009199)">
          <span>2022年5月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1654009200, 1656601199)">
          <span>2022年6月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1656601200, 1659279599)">
          <span>2022年7月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1659279600, 1661957999)">
          <span>2022年8月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1661958000, 1664549999)">
          <span>2022年9月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1664550000, 1667228399)">
          <span>2022年10月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1667228400, 1669820399)">
          <span>2022年11月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1669820400, 1672498799)">
          <span>2022年12月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
        <div class="list-title" @click="displayFunc(1672498800, 1675177199)">
          <span>2023年1月</span>
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
      </div>
      <div class="list-titles-sp">
        <div>
          <label for="select-chat-ranges">年月：</label>
          <select @change="displayFunc(null, null)" class="select-range" name="select-range" id="select-range">
            <option value=""></option>
            <option value="1625065200,1627743598">2020年7月</option>
            <option value="1627743600,1630421999">2021年8月</option>
            <option value="1630422000,1633013999">2021年9月</option>
            <option value="1633014000,1635692399">2021年10月</option>
            <option value="1635692400,1638284399">2021年11月</option>
            <option value="1638284400,1640962799">2021年12月</option>
            <option value="1640962800,1643641199">2022年1月</option>
            <option value="1643641200,1646060399">2022年2月</option>
            <option value="1646060400,1648738799">2022年3月</option>
            <option value="1648738800,1651330799">2022年4月</option>
            <option value="1651330800,1654009199">2022年5月</option>
            <option value="1654009200,1656601199">2022年6月</option>
            <option value="1656601200,1659279599">2022年7月</option>
            <option value="1659279600,1661957999">2022年8月</option>
            <option value="1661958000,1664549999">2022年9月</option>
            <option value="1664550000,1667228399">2022年10月</option>
            <option value="1667228400,1669820399">2022年11月</option>
            <option value="1669820400,1672498799">2022年12月</option>
            <option value="1672498800,1675177199">2023年1月</option>
          </select>
          <ion-icon @click="toggleSpSearch" class="chat-search" name="search-outline"></ion-icon>
        </div>
      </div>
      <div v-show="spSearchShow" class="search-div-sp">
        <div class="search-panel-sp">
          <table class="search-panel-sp-table">
            <tr>
              <td>
                <div>
                  <select class="search-speaker" v-model="searchSpeaker" name="search-speaker">
                    <option value="" class="search-speaker-placeholder"></option>
                    <option v-for="(value, key) in searchSpeakers" :key="key" :value="value">
                      {{ value }}
                    </option>
                  </select>
                </div>
              </td>
              <td>

              </td>
              <td>
                <div @click="toggleSpSearch" class="search-hide">
                  <ion-icon class="search-panel-close" name="close-outline"></ion-icon>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <input type="text" v-model="searchText" class="search-text" placeholder="検索条件（必須）">
              </td>
              <td>
                <div>
                  <button @click="search" class="search-btn">検索</button>
                </div>
              </td>
              <td>
              </td>
            </tr>
          </table>
          <div class="search-result">
            <div v-for="(value, key) in searchResult" :key="key" class="search-result-unit" @click="choiceSearchResult(value.id)">
              <span class="comment-value">{{ value.comment }}</span>
            </div>
          </div>
        </div>
      </div>
      <div id="contents" class="contents">
        <div class="chat-unit" v-for="(value, key) in displaydatas" :key="key" :id="value.id">
          <div>
            <span class="speaker-value">{{ value.speaker }}</span>
            <span class="date-value">{{ value.commentdateFmt }}</span>
          </div>
          <div v-if="value.returnto">
            <span class="returnto-title"><ion-icon name="arrow-undo-outline"></ion-icon>RE</span>
            <span class="returnto-value">{{ value.returnto }}</span>
          </div>
          <div class="comment-div">
            <span class="comment-value">{{ value.comment }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../../main.js"
import { collection, getDocs } from "firebase/firestore";

import MemberHeader from '../../components/MemberHeader.vue';
import { getAuth, onAuthStateChanged } from "@firebase/auth";

export default {
  name: 'ChatLog',
  components: {
    MemberHeader,
  },
  data() {
    return {
      datas: [],
      displaydatas: [],
      mode: 'dt',
      searchShow: false,
      spSearchShow: false,
      searchSpeakers: [],
      searchSpeaker: "",
      searchText: "",
      searchResult: [],
    }
  },
  beforeCreate() {
    onAuthStateChanged(getAuth(), (user) => {
      if (!user) {
        this.$router.push("/member/signin");
      }
    });
  },
  mounted() {
    console.log("ChatLog");

		document.getElementById("account").style.color = "black";
		document.getElementsByClassName("menu").forEach(x => {
			x.style.color = "black";
		});

    if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
      document.getElementById("contents").style.height = (window.innerHeight
                                                          - document.getElementsByClassName("header")[0].clientHeight
                                                          - document.getElementsByClassName("member-header")[0].clientHeight
                                                          - document.getElementsByClassName("list-titles-sp")[0].clientHeight
                                                          - 40
                                                         ) + "px";

      this.mode = "sp";
    }

    this.onloadFunc();  
  },
  methods: {
    async onloadFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "ChatLog"));
      let speakers = new Set();
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        data.commentdateFmt = data.commentdate ? this.formatDate(data.commentdate) : null;
        if (data.speaker) {
          speakers.add(data.speaker);
        }
        this.datas.push(data);
      });
      this.searchSpeakers = speakers;
    },
    formatDate(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date.getFullYear() + "年"
          + (date.getMonth() + 1) + "月"
          + date.getDate() + "日 "
          + ("00" + date.getHours()).slice(-2) + ":"
          + ("00" + date.getMinutes()).slice(-2);
    },
    displayFunc(start, end) {
      if (start == null) {
        let range = document.getElementById("select-range").value;
        if (range) {
          start = parseInt(range.split(",")[0]);
          end = parseInt(range.split(",")[1]);
        } else {
          return;
        }
      }
      // \[To.*?\]
      // \[返信.*?\]
      this.displaydatas = this.datas.filter(x => x.commentdate.seconds >= start && x.commentdate.seconds <= end);
      this.displaydatas.sort((a, b) => a.commentdate.seconds - b.commentdate.seconds);
      this.$nextTick(() => {
        let chatUnits = document.getElementsByClassName("chat-unit");
        chatUnits.forEach((x, i) => {
          i++;
          if ((i % 2) != 0) {
            x.classList.add('chat-unit-odd');
          }
        });
        document.getElementsByClassName("comment-value").forEach(x => {
          let text = x.innerText;
          if (text.search(/(\[To.*?\])/) >= 0 || text.search(/(\[返信.*?\])/) >= 0) {
            if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
              text = x.innerText.replace(/(\[To.*?\])/g, '<span style="font-size: 1rem; border-radius: 6px; padding: 2px 6px; margin-right: 0.2vw; background-color: #5959ff; color: white;">TO</span>');
              text = text.replace(/(\[返信.*?\])/g, '<span style="font-size: 1rem; border-radius: 6px; padding: 2px 6px; margin-right: 0.2vw; background-color: lightslategray; color: white;"><ion-icon name="arrow-undo-outline"></ion-icon>RE</span>');
            } else {
              text = x.innerText.replace(/(\[To.*?\])/g, '<span style="font-size: 0.6vw; border-radius: 6px; padding: 3px; margin-right: 0.2vw; background-color: #5959ff; color: white;">TO</span>');
              text = text.replace(/(\[返信.*?\])/g, '<span style="font-size: 0.6vw; border-radius: 6px; padding: 3px; margin-right: 0.2vw; background-color: lightslategray; color: white;"><ion-icon name="arrow-undo-outline"></ion-icon>RE</span>');
            }
            x.innerHTML = text;
          }
        });
      });
    },
    search() {
      if (!this.searchText) {
        alert("検索したい文字列を入力してください");
        return;
      }
      this.searchResult = this.datas.filter(x => x.comment.indexOf(this.searchText) >= 0);
      if (this.searchSpeaker) {
        this.searchResult = this.searchResult.filter(x => x.speaker == this.searchSpeaker);
      }
      if (this.searchResult.length == 0) {
        alert("検索結果はありません");
        return;
      }
      this.$nextTick(() => {
        let searchResultUnits = document.getElementsByClassName("search-result-unit");
        searchResultUnits.forEach((x, i) => {
          i++;
          if ((i % 2) === 0) {
            x.classList.add('search-result-even');
          } else {
            x.classList.add('search-result-odd');
          }
        });
      });
    },
    choiceSearchResult(id) {
      if (confirm("該当行に移動しますか？")) {
        const target = this.datas.find(x => x.id == id);
        let start, end;
        if (target.commentdate.seconds >= 1654009200 && target.commentdate.seconds <= 1656601199) {
          start = 1654009200;
          end = 1656601199;
        } else if (target.commentdate.seconds >= 1656601200 && target.commentdate.seconds <= 1659279599) {
          start = 1656601200;
          end = 1659279599;
        } else if (target.commentdate.seconds >= 1659279600 && target.commentdate.seconds <= 1661957999) {
          start = 1659279600;
          end = 1661957999;
        } else if (target.commentdate.seconds >= 1661958000 && target.commentdate.seconds <= 1664549999) {
          start = 1661958000;
          end = 1664549999;
        } else if (target.commentdate.seconds >= 1664550000 && target.commentdate.seconds <= 1667228399) {
          start = 1664550000;
          end = 1667228399;
        } else if (target.commentdate.seconds >= 1667228400 && target.commentdate.seconds <= 1669820399) {
          start = 1667228400;
          end = 1669820399;
        } else if (target.commentdate.seconds >= 1669820400 && target.commentdate.seconds <= 1672498799) {
          start = 1669820400;
          end = 1672498799;
        } else if (target.commentdate.seconds >= 1672498800 && target.commentdate.seconds <= 1675177199) {
          start = 1672498800;
          end = 1675177199;
        }
        this.displayFunc(start, end);
        this.$nextTick(() => {
          document.getElementById(id).scrollIntoView({  });
        });
        if (this.mode == 'dt') {
          this.toggleSearch();
        } else {
          this.toggleSpSearch();
        }
      }
    },
    toggleSearch() {
      this.searchShow = !this.searchShow;
    },
    toggleSpSearch() {
      this.spSearchShow = !this.spSearchShow;
    },
    onReady() {
      this.$refs.youtube.stopVideo();
    },
  }
}
</script>

<style scoped>
.chat-log {
  width: 70%;
	margin: 5vw auto 0;
}
.chat-log-contents {
  display: inline-flex;
  width: 100%;
}
.list-titles {
  text-align: left;
  margin-left: 1vw;
  width: 30%;
}
.list-titles-sp {
  display: none;
}
.list-titles div {
  margin-left: 1vw;
}
.list-title {
  border: 1px solid aliceblue;
  background-color: aliceblue;
  width: 13vw;
  padding-left: 0.5vw;
  margin-top: 0.3vw;
}
.contents {
  width: 90%;
  margin-top: 1.6vw;
  height: 600px;
  overflow: auto;
}
.chat-unit {
  margin-bottom: 0.8vw;
  text-align: left;
  width: 98%;
  table-layout: fixed;
  transition: all .3s;
  padding: 0.4vw;
}
.chat-unit:hover {
  top: -3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
.speaker-value {
  font-weight: bold;
}
.date-value {
  float: right;
  font-size: 0.7vw;
  font-weight: bold;
}
.returnto-title {
  font-size: 0.6vw;
  border-radius: 6px;
  padding: 3px;
  margin-right: 0.2vw;
  background-color: lightslategray;
  color: white;
}
.comment-div {
  padding-left: 1vw;
}
.comment-value {
  white-space: pre-line;
  overflow-wrap: anywhere;
}
.chat-search {
  width: 2vw;
  height: 2vw;
  vertical-align: middle;
  position: relative;
  margin-bottom: 0.5vw;
}
.search-div {
  display: grid;
}
.search-div-sp {
  display: none;
}
.search-div {
  display: grid;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
}
.search-panel {
  width: 60%;
  height: 60%;
  background-color: rgb(254 240 255);
  position: absolute;
  margin: auto;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.search-input {
  margin: 1vw 0;
  display: inline-grid;
}
.search-input div {
  margin: 0.2vw 0;
  display: inline-flex;
}
.search-panel-close {
  float: right;
  width: 3vw;
  height: 3vw;
}
.search-speaker {
  height: 1.6vw;
  margin-right: 1vw;
}
.search-text {
  height: 1.2vw;
  width: 17vw;
  margin-right: 1vw;
}
.search-result {
  text-align: left;
  position: relative;
  height: 77%;
  overflow: auto;
}
.search-result div {
  width: 90%;
  margin: 0.2vw auto;
  border: 1px solid var(--color);
  padding: 0.3vw;
}
.search-result-even {
  background-color: #f5f5f5;
}
.search-result-odd {
  background-color: #fff2f2;
}
.chat-unit-odd {
  background-color: #f8f8f8;
}
@media screen and (max-width: 640px) {
  .chat-log {
    width: 100%;
  }
  .chat-log-contents {
    width: 100%;
    display: inline-grid;
  }
  .list-titles {
    display: none;
  }
  .list-titles-sp {
    display: inline-flex;
    margin: 2vw auto;
  }
  .select-range {
    width: 40vw;
    height: 7vw;
    margin-top: 2vw;
  }
  .contents {
    width: 95%;
    margin: 1.6vw auto;
    height: 600px;
    overflow-y: auto;
    position: relative;
    overflow-x: hidden;
  }
  .chat-unit {
    margin-bottom: 6vw;
    margin-right: unset;
    text-align: left;
    border-collapse: separate;
    width: 100%;
    table-layout: fixed;
    transition: all .3s;
  }
  .date-value {
    font-size: 3vw;
  }
  .chat-search {
    width: 6vw;
    height: 6vw;
    vertical-align: middle;
    margin-left: 2vw;
    position: absolute;
    right: 2vw;
  }
  .search-div {
    display: none;
  }
  .search-div-sp {
    display: grid;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 999;
  }
  .search-panel-sp {
    width: 90%;
    height: 90%;
    background-color: rgb(254 240 255);
    position: absolute;
    margin: auto;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .search-input {
    margin-top: 10vw;
  }
  .search-input div {
    display: inline-flex;
  }
  .search-text {
    height: 5vw;
    width: 60vw;
  }
  .search-btn {
    border: none;
    background-color: #f97272;
    color: white;
    margin-left: 2vw;
    height: 7vw;
    width: 12vw;
    border-radius: 6px;
  }
  .search-panel-close {
    float: right;
    margin-top: 1vw;
    margin-right: 1vw;
    width: 8vw;
    height: 8vw;
  }
  .search-speaker {
    width: 60vw;
    margin-bottom: 3vw;
    height: 7vw;
  }
  .search-speaker-placeholder {
    color: lightgray;
  }
  .search-result {
    text-align: left;
    position: relative;
    height: 81%;
    overflow: auto;
    margin-top: 2vw;
  }
  .search-result div {
    width: 90%;
    margin: 2vw auto;
    border: 1px solid var(--color);
    padding: 1vw;
  }
  .search-result-even {
    background-color: #f5f5f5;
  }
  .search-result-odd {
    background-color: #fff2f2;
  }
}
</style>
