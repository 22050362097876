<template>
  <div id="ringo" class="ringo">
    <div class="ringo_logo">
      <img class="" src="../../assets/site_images/ringo.png" alt="logo">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Ringo',
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log("Ringo");
    document.getElementsByClassName("header")[0].style.marginBottom = "unset";
    this.divReload();
    window.onload = ()=> {
      this.divReload();
    }
    document.getElementById("account").style.color = "white";
		document.getElementsByClassName("menu").forEach(x => {
		x.style.color = "white";
	});
  },
  methods: {
    divReload() {
			let ringoHeight = window.innerHeight - document.getElementsByClassName("footer")[0].clientHeight;
			document.getElementById("ringo").style.height = ringoHeight + "px";
			let ringoWidth = window.innerWidth;
			if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
				document.getElementById("ringo").style.width = ringoWidth + "px";
			} else {
				document.getElementById("ringo").style.width = ringoWidth - 10 + "px";
			}
    }
  }
}
</script>

<style scoped>
.ringo {
  background: linear-gradient(to bottom left, #ea437e, #f08a48);
}
.ringo_logo {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:100%;
}
@media screen and (max-width: 640px) {
  .ringo {
  }
}
</style>
