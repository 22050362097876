<template>
  <div class="music-regist">
		<div class="categories">
			<span class="sectionTitle">カテゴリ</span>
			<div v-for="(value, index) in categories" :key="index">
				<div>
					<input type="text" class="categoryInput" @change="categoryInput(index, $event)" :value="value.category">
				</div>
			</div>
			<input type="text" class="newCategoryInput" @change="categoryInput(null, $event)" placeholder="New Category">
		</div>
    <div class="regist">
			<span class="sectionTitle">Register</span>
      <table>
        <tr>
          <th>Question</th>
          <td><textarea v-model="question" class="questionInput" placeholder="Question"></textarea></td>
        </tr>
        <tr>
          <th>Answer</th>
          <td><textarea v-model="answer" class="answerInput" placeholder="Answer"></textarea></td>
        </tr>
				<tr>
					<th>Category</th>
					<td>
						<select name="categorySelect" id="categorySelect" v-model="categorySelectValue">
							<option value=""></option>
							<option v-for="value in categorySelect" :key="value" :value="value.key">{{value.value}}</option>
						</select>
					</td>
				</tr>
        <tr>
          <td colspan="2">
            <ion-icon class="input-ud-icon" @click="postData" name="save-outline"></ion-icon>
          </td>
        </tr>
      </table>
    </div>
		<div class="qas">
			<span class="sectionTitle">Q & A</span>
			<div class="qasWrap">
				<div v-for="category in categories" :key="category">
					<div class="categoryTitle">{{category.category}}</div>
					<div class="pre-qas" v-for="qa in qas" :key="qa">
						<div class="toggleable" v-if="category.category == qa.category">
							<input class="toggleable__control hidden--visually" :id="'toggleable' + qa.id" type="checkbox" />
							<label class="toggleable__label" :for="'toggleable' + qa.id">
								Q. {{ qa.question }}
							</label>
							<div style="white-space: pre-wrap;" class="toggleable__content" v-text="'A. ' + qa.answer"></div>
							<div class="editIcon">
								<img src="../../assets/icons/edit.svg" @click="deploymentData(qa.id)" alt="edit">
							</div>
							<div class="trashIcon">
								<img src="../../assets/icons/trash.svg" @click="deleteData(qa.id)" alt="trash">
							</div>
							<div class="sortIcon">
								<div @click="up(qa.id)">▲</div>
								<div @click="down(qa.id)">▼</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
import firebase from "../../main.js"
import { collection, doc, getDocs, addDoc, updateDoc, deleteDoc } from "firebase/firestore";

export default {
  name: 'MusicQARegist',
  props: {
  },
  data() {
    return {
      categories: [],
			qas: [],
			categorySelect: [],
			question: null,
			answer: null,
			categorySelectValue: null,
			id: null,
    }
  },
  mounted() {
    console.log("MusicQARegist");

		document.getElementById("account").style.color = "black";
		document.getElementsByClassName("menu").forEach(x => {
			x.style.color = "black";
		});
    document.getElementById("footer").style.display = "none";

    this.onloadFunc();  
  },
  unmounted() {
    document.getElementById("footer").style.display = "grid";
  },
  methods: {
    awaitFunc() {
      this.categories = [];
      this.onloadFunc();
    },
    async onloadFunc() {
      const mqacQuerySnapshot = await getDocs(collection(firebase.db, "MusicQACategories"));
      mqacQuerySnapshot.forEach((mqac) => {
        let data = mqac.data();
        data.id = mqac.id;
        this.categories.push(data);
      });
      const mqaQuerySnapshot = await getDocs(collection(firebase.db, "MusicQAs"));
      mqaQuerySnapshot.forEach((mqa) => {
        let data = mqa.data();
        data.id = mqa.id;
        this.qas.push(data);
      });

			this.categories.sort((a, b) => a.number - b.number);
			this.categories.forEach(x => {
				this.categorySelect.push({ key: x.category, value: x.category });
			});
			this.qas.sort((a, b) => a.number - b.number);
    },
		validate() {
			if (!this.question) {
				alert("質問が入力されていません(´·ω·`)");
				return false;
			}
			if (!this.answer) {
				alert("答えが入力されていません(´·ω·`)");
				return false;
			}
			if (!this.categorySelectValue) {
				alert("カテゴリが選択または入力されていません(´·ω·`)");
				return false;
			}
			return true;	
		},
		async categoryInput(index, event) {
			if (this.categories.includes(event.target.value)) {
				return;
			}
			const val = event.target.value;
			let data = {
				category: val
			};

			if (index) {
				let target = this.categories[index];
				const beforeCategory = target.category;
				target.category = val;
				await updateDoc(doc(firebase.db, "MusicQACategories", target.id), data);
				this.categories.splice(index, 1, target);
				this.categorySelect.splice(index, 1, { key: target.category, value: target.category });

				this.qas.forEach((qa, i) => {
					if (qa.category == beforeCategory) {
						qa.category = val;
						this.qas.splice(i, 1, qa);
					}
				});

			} else {
				data.number = this.categories.length;
				this.categories.push(data);
				this.categorySelect.splice(this.categorySelect.length - 1, 0, {key: val, value: val});

				const db = collection(firebase.db, "MusicQACategories");
				await addDoc(db, data);
			}

			event.target.value = null;
		},
    async postData() {
			if (!this.validate()) {
				return;
			}
      const data = {
        question: this.question,
        answer: this.answer,
				category: this.categorySelectValue,
      }

      if (this.id) {
        await updateDoc(doc(firebase.db, "MusicQAs", this.id), data);
				let index = 0;
				let target = null;
				this.qas.forEach((x, i) => {
					if (x.id == this.id) {
						index = i;
						target = x;
						return;
					}
				});
				target.question = this.question;
				target.answer = this.answer;
				target.category = this.categorySelectValue;

				this.qas.splice(index, 1, target);

      } else {
        const db = collection(firebase.db, "MusicQAs");
				data.number = this.qas.length;
        const docRef = await addDoc(db, data);

				data.id = docRef.id;
				this.qas.push(data);
      }

			this.clearData();

    },
		async up(id) {
			let index = 0;
			let target = null;
			this.qas.forEach((x, i) => {
				if (x.id == id) {
					index = i;
					target = x;
					return;
				}
			});
			if (index == 0) {
				return;
			}
			target.number = index - 1;
			await updateDoc(doc(firebase.db, "MusicQAs", target.id), { number: target.number });
			this.qas[index - 1].number = this.qas[index - 1].number + 1;
			await updateDoc(doc(firebase.db, "MusicQAs", this.qas[index - 1].id), { number: this.qas[index - 1].number });
			this.qas.sort((a, b) => a.number - b.number);
		},
		async down(id) {
			let index = 0;
			let target = null;
			this.qas.forEach((x, i) => {
				if (x.id == id) {
					index = i;
					target = x;
					return;
				}
			});
			if (index == this.qas.length -1) {
				return;
			}
			target.number = index - 1;
			await updateDoc(doc(firebase.db, "MusicQAs", target.id), { number: target.number });
			this.qas[index - 1].number = this.qas[index - 1].number + 1;
			await updateDoc(doc(firebase.db, "MusicQAs", this.qas[index - 1].id), { number: this.qas[index - 1].number });
			this.qas.sort((a, b) => a.number - b.number);
		},
    async deleteData(id) {
			let index = 0;
			let target = null;
			this.qas.forEach((x, i) => {
				if (x.id == id) {
					index = i;
					target = x;
					return;
				}
			});
				
			if (confirm("【" + target.category + " :: " + target.question + "】を削除してもいいですか？")) {
				await deleteDoc(doc(firebase.db, "MusicQAs", id));

				this.qas.splice(index, 1);
			}

    },
    clearData() {
			this.id = null;
			this.question = null;
			this.answer = null;
			this.categorySelectValue = null;
    },
    deploymentData(id) {
			const data = this.qas.find(x => x.id == id);
			if (data) {
				this.id = data.id;
				this.question = data.question;
				this.answer = data.answer;
				this.categorySelectValue = data.category;
			}
    }
  }
}
</script>

<style scoped>
.music-regist {
	position: relative;
  display: inline-flex;
  margin: 0 auto;
	width: 95%;
}
.categories {
	width: 15%;
}
.regist {
	position: relative;
	width: 30%;
}
.sectionTitle {
	font-size: 1.6rem;
	margin: 0 auto;
	border-bottom: 3px solid black;
	line-height: 4vw;
}
.categoryInput {
	background-color: #fff1ec;
}
.newCategoryInput {
	background-color: #f5ffec;
}
.newCategoryInput::placeholder {
	color: rgb(187, 187, 187);
}
.questionInput {
  height: 4vw;
}
.answerInput {
  height: 10vw;
}
table {
	width: 100%;
}
th {
	text-align: right !important;
	width: 25%;
}
input {
	margin-bottom: 0.6vw;
}
textarea {
  margin-bottom:10px;
}
input, textarea, select {
	position: relative;
  font-size: 1em;
  padding: 15px 10px 10px;
  font-family: 'Source Sans Pro',arial,sans-serif;
  border: 1px solid #cecece;
  background: #e9ffe9;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 95%;
	float: right;
}
input::placeholder, textarea::placeholder {
	color: rgb(187, 187, 187);
}
select {
	color: black;
	margin-bottom: 0.6vw;
}
option {
	background-color: white !important;
	color: black;
}
::-webkit-input-placeholder {
   color: #FAFAFA;
}
:-moz-placeholder {
   color: #FAFAFA;  
}
::-moz-placeholder {
   color: #FAFAFA; 
}
:-ms-input-placeholder {  
   color: #FAFAFA;  
}
.input-ud-icon {
	position: relative;
	display: block;
	margin-top: 15px;
	background-color: #2ABCA7;
	padding: 10px 45px;
	border-radius: 5px;
	border: 1px solid #2ABCA7;
	transition: .5s;
	cursor: pointer;
	width: 20%;
	color: #fff;
	float: right;
	font-size: 1.4rem;
}
button:hover, .button:hover {
  background:#19a08c;
}
label.error {
    font-family:'Source Sans Pro',arial,sans-serif;
    font-size:1em;
    display:block;
    padding-top:10px;
    padding-bottom:10px;
    background-color:#d89c9c;
    width: 80%;
    margin:auto;
    color: #FAFAFA;
    -webkit-border-radius:6px;
    -moz-border-radius:6px;
    border-radius:6px;
}
/* media queries */
@media (max-width: 700px) {
  label.error {
    width: 90%;
  }
  input, textarea {
    width: 90%;
  }
  button {
    width:90%;
  }
  body {
  padding-top:10px;
  }  
}
.message {
    font-family:'Source Sans Pro',arial,sans-serif;
    font-size:1.1em;
    display:none;
    padding-top:10px;
    padding-bottom:10px;
    background-color:#2ABCA7;
    width: 80%;
    margin:auto;
    color: #FAFAFA;
    -webkit-border-radius:6px;
    -moz-border-radius:6px;
    border-radius:6px;
}
.toggleable__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .8s cubic-bezier(0,1,0,1);
	text-align: left;
}
.toggleable__control:checked ~ .toggleable__content {
  transition-timing-function: ease-in-out;
  max-height: 800px;
}
/* Hiding elements in an accessible way */
.hidden--visually {
    border: 0;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
/* Theming CSS */
body {
  max-width: 600px;
  padding: 3rem 1rem;
  font-size: 1.25rem;
  line-height: 1.333;
  font-family: sans-serif;
  margin: 0 auto;
}
.toggleable {
  border-bottom: 2px solid lightgray;
	position: relative;
}
.toggleable__label {
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem 0;
  cursor: pointer;
}
.toggleable__content {
  margin-top: 1rem;
  padding: 0 2rem 0 4rem;
}
.toggleable__control:checked ~ .toggleable__content {
  margin-bottom: 1rem;
}
.toggleable__label::after {
  content: "▼";
    font-size: 0.8rem;
}
.toggleable__control:checked ~ .toggleable__label::after {
  content: "▲";
}
.qasWrap {
	height: 30vw;
	overflow-y: auto;
}
.qas {
	width: 55%;
	padding-left: 2vw;
}
.pre-qas {
	margin: 0 auto 1vw;
	padding-right: 6vw;
}
.categoryTitle {
	font-size: 1.2rem;
	line-height: 2vw;
	font-weight: bold;
	background-color: #ebebeb;
	height: 2vw;
	border-radius: 10px;
}
.editIcon {
	position: absolute;
	right: -2vw;
	top: 1vw;
}
.editIcon img {
	width: 1vw;
	cursor: pointer;
}
.trashIcon {
	position: absolute;
	right: -4vw;
	top: 1vw;
}
.trashIcon img {
	width: 1vw;
	cursor: pointer;
}
.sortIcon {
	position: absolute;
	right: -5.6vw;
	top: 0.7vw;
}
.sortIcon div {
	font-size: 1rem;
	line-height: 1rem;
	cursor: pointer;
}
</style>
