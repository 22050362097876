<template>
  <div class="music-sales">
  </div>
</template>

<script>

export default {
  name: 'MusicSales',
	components: {
	},
  props: {
  },
  data() {
    return {
    }
  },
  beforeCreate() {
  },
  mounted() {
  },
  unmounted() {
  },
  methods: {
  }
}
</script>
<style scoped>
</style>