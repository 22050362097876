<template>
  <div id="flow" class="flow">
    <div class="waku">
      <img src="../../assets/site_images/flow/omoushi-baner.png" alt="">
      ページ下部の「申し込みボタン」から参加申し込みができます。<br>
      ※この申込ページは紹介者専用ページです。
      <table class="step">
        <tr>
          <th>
            入会申込みのステップ
          </th>
        </tr>
        <tr>
          <td>
            <b>4つのステップ</b>で入会が完了します。
            <ol>
              <li><b>下部の「申し込みボタン」から必要項目を入力。</b></li>
              <li><b>申し込み後の支払いページよりクレジットカード情報を入力</b></li>
              <li><b>メールにて会員サイト情報とチャットワーク招待URLをお知らせ</b></li>
              <li><b>非公開チャットワークグループへの参加申込み</b></li>
            </ol>
          </td>
        </tr>
      </table>
      <div class="zehi">
        ぜひ、リンゴ村への参加お待ちしています♪
      </div>
      <div class="chat">
        ※メインの交流はチャットワークを使って行います。<br>
        ※この申込ページは紹介者専用ページです。<br>
        チャットワークのアカウントをお持ちでない方は、「<a href="https://go.chatwork.com/ja/" target="_blank">チャットワークのアカウントを作成</a>」より、
        チャットワークのアカウントを作成もお願いします。<br>
        アカウントの作成方法がご不明の場合は、「<a href="https://ringo-mura.com/chatwork-manual1/" target="_blank">チャットワーク登録マニュアル</a>」を参照ください。<br>
      </div>
      <div>
        <a href="https://r1d.jp/p/r/AKjijzxg" target="_blank">
          <img src="../../assets/site_images/flow/omoushi-link-30.png" alt="">
        </a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Flow',
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log("Flow");
    document.getElementById("header").style.display = "none";
  },
  methods: {
  }
}
</script>

<style scoped>
.flow {
  width: 80%;
  margin: 2vw auto;
}
.waku {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.18);
  display: inline-grid;
  text-align: left;
  padding: 2vw;
}
img {
  margin: 0 auto;
}
.step {
  width: 70%;
  margin: 2vw auto 0;
}
.step th {
  height: 3vw;
  text-align: center;
  background-color: #ffb49e;
  font-size: 21px;
  font-weight: 500;
  color: white;
  border-radius: 10px 10px 0 0;
}
.step td {
  padding: 20px;
  background-color: #FFF4F0;
  border-radius: 0 0 10px 10px;
}
ol {list-style:none; counter-reset: my-counter;}
ol > li {margin:2em 0 0 4.5em; position:relative; display:block;}
ol > li:before {
  content: counter(my-counter);
  counter-increment: my-counter;
  color:#fff;
  text-align:center;
  line-height: 0.2em;
  width: 0.2vw;
  height: 0.2vw;
  margin:0 0 0 -2vw; 
  padding: 0.5vw;
  background: #FFCD44;
  border-radius:50%;
  position:absolute;
  display:block;
  float:left;
}
ol > li > h1 {margin:0;}
ol > li > p {margin:0;}
.zehi {
  margin: 2vw 0;
}
a {
  margin: 0 auto;
  display: flex;
}
.chat {
  font-size: 0.9em;
  line-height: 2em;
  margin-bottom: 2vw;
}
.chat a { 
  display: inline;
}
@media screen and (max-width: 640px) {
  .flow {
  }
}
</style>
