<template>
  <div class="signin">
    <table>
      <tr>
        <th>メールアドレス：</th>
        <td><input type="email" id="email" placeholder="メールアドレス"></td>
      </tr>
      <tr>
        <th>パスワード：</th>
        <td><input type="password" @keydown.enter="signin" placeholder="パスワード" id="password"></td>
      </tr>
      <tr>
        <td colspan="2">
          <button class="singin-btn" @click="signin">サインイン</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { getAnalytics, logEvent } from "@firebase/analytics";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";

export default {
  name: 'Signin',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  beforeCreate() {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        this.$router.push("/member");
      }
    });
  },
  mounted() {
    console.log("Signin");

		document.getElementById("account").style.color = "black";
		document.getElementsByClassName("menu").forEach(x => {
			x.style.color = "black";
		});
  },
  methods: {
    signin() {
      const email = document.getElementById("email").value;
      const password = document.getElementById("password").value;
      if (!email) {
        alert("メールアドレスを入力してください");
        return;
      }
      if (!password) {
        alert("パスワードを入力してください");
        return;
      }
      signInWithEmailAndPassword(getAuth(), email, password)
      .then((user) => {
        // 成功時の処理
        const analytics = getAnalytics();
        logEvent(analytics, 'signin', { email: user.email });
        alert("おかえりなさい！");
        this.$router.push("/member/seminar");
      })
      .catch((error) => { 
        // エラー時の処理 
        console.log(error);
        alert("ログインに失敗しました");
      });
    },
  }
}
</script>

<style scoped>
.signin {
  margin: 5vw auto 0;
}
table {
  margin-top: 2vw;
}
th {
  font-family: 'Kiwi Maru', cursive;
  text-align: right;
  height: 4vw;
}
input {
  height: 1.4vw;
  width: 16vw;
}
button {
  font-family: 'Kiwi Maru', cursive;
  width: 6vw;
  height: 1.8vw;
  font-size: 0.8vw;
  border-radius: 10px;
  border: none;
  background-color: rgb(253, 219, 225);
}
@media screen and (max-width: 640px) {
  .signin {
    width: 100%;
  }
  table {
    margin: 20vw auto;
    width: 60%;
  }
  th {
    display: none;
  }
  input {
    height: 10vw;
    width: 100%;
    font-size: 3vw;
  }
  button {
    font-family: initial;
    margin-top: 2vw;
    width: 25vw;
    height: 10vw;
    font-size: 4vw;
    border-radius: 10px;
    border: none;
    background-color: rgb(253, 219, 225);
  }
}
</style>
