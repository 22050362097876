<template>
  <div id="present" class="present">
    <img class="present-img present-0" src="../../assets/site_images/present/present_0.png" alt="present-0">
    <img class="present-img present-1" src="../../assets/site_images/present/present_1.png" alt="present-1">
    <div class="free">今だけ限定！<br>以下の2つの動画を無料プレゼント！</div>
    <img class="present-img present-3" src="../../assets/site_images/present/present_3.png" alt="present-3">
    <img class="present-img present-4" src="../../assets/site_images/present/present_4.png" alt="present-4">
    <img class="present-img present-5" src="../../assets/site_images/present/present_5.png" alt="present-5">
    <div class="tokuten-form-banner"><h2>特典申し込みフォーム</h2></div>
    <div class="content-form">
      <form action="https://r1d.jp/p/r/V5weizXY" enctype="multipart/form-data" id="UserItemForm" class="myForm" method="post" accept-charset="utf-8">
        <input type="hidden" name="_method" value="POST">
        <div class="input text input_unit  required">
          <label for="Usermail" class="form-input-label">メールアドレス：</label>
          <input name="data[User][mail]" id="Usermail" value="" class="required validate[required] validate[custom[email]] required_color" type="text" required="required">
        </div>
        <div class="content-form-caution">
          登録後すぐに無料コンテンツを受け取る事ができます。<br>
          記入されたアドレスに、メールをお送りいたしますので、ご確認下さい。
        </div>
        <div class="conten-form-attention">
          Gmailでの登録の場合、パソコンからメールを確認する際は「プロモーション」フォルダに振り分けられる可能性がありますので、ご注意ください。
        </div>
        <div>
          <input class="submit-form-input-submit" type="submit" value="確認する">
        </div>
        <input type="hidden" id="server_url" value="https://r1d.jp/">
        <input type="hidden" name="data[User][referer_form_url]" value="" class="UserRefererFormUrl">
        <input type="hidden" name="data[User][referer_url]" value="" class="UserRefererUrl">
      </form>
    </div>
    <img class="present-img present-6" src="../../assets/site_images/present/present_6.png" alt="present-6">
    <div class="video">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/v6Raqu0B66U" title="リンゴってどんな人？" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <img class="present-img present-7" src="../../assets/site_images/present/present_7.png" alt="present-7">
    <img class="present-img present-8" src="../../assets/site_images/present/present_8.png" alt="present-8">
    <img class="present-img present-9" src="../../assets/site_images/present/present_9.png" alt="present-9">
    <img class="present-img present-10" src="../../assets/site_images/present/present_10.png" alt="present-10">
    <div class="tokuten-form-banner"><h2>特典申し込みフォーム</h2></div>
    <div class="content-form">
      <form action="https://r1d.jp/p/r/V5weizXY" enctype="multipart/form-data" id="UserItemForm" class="myForm" method="post" accept-charset="utf-8">
        <input type="hidden" name="_method" value="POST">
        <div class="input text input_unit  required">
          <label for="Usermail" class="form-input-label">メールアドレス：</label>
          <input name="data[User][mail]" id="Usermail" value="" class="required validate[required] validate[custom[email]] required_color" type="text" required="required">
        </div>
        <div class="content-form-caution">
          登録後すぐに無料コンテンツを受け取る事ができます。<br>
          記入されたアドレスに、メールをお送りいたしますので、ご確認下さい。
        </div>
        <div class="conten-form-attention">
          Gmailでの登録の場合、パソコンからメールを確認する際は「プロモーション」フォルダに振り分けられる可能性がありますので、ご注意ください。
        </div>
        <div>
          <input class="submit-form-input-submit" type="submit" value="確認する">
        </div>
        <input type="hidden" id="server_url" value="https://r1d.jp/">
        <input type="hidden" name="data[User][referer_form_url]" value="" class="UserRefererFormUrl">
        <input type="hidden" name="data[User][referer_url]" value="" class="UserRefererUrl">
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Present',
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log("Present");
  },
  methods: {
  }
}
</script>

<style scoped>
.present {
  margin: 0 auto 5vw;
  display: inline-grid;
}
.present-img {
  margin: 0 auto;
}
.present-0 {
  width: 650px;
  height: auto;
}
.present-1, .present-2, .present-3, .present-6, .present-7 {
  width: 900px;
  height: auto;
}
.present-4 {
  width: 415px;
  height: auto;
}
.present-5 {
  width: 150px;
  height: auto;
  margin: 2vw auto;
}
.present-8, .present-9, .present-10 {
  width: 680px;
  height: auto;
  margin-bottom: 1vw;
}
.tokuten-form-banner {
  width: 900px;
  height: 92px;
  background-color: #fc858b;
  margin: 0 auto 1vw;
  border-radius: 20px;
}
.tokuten-form-banner h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 0px;
  padding-right: 0px;
  border: 0;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  text-align: center;
  color: black;
  font-weight: 700;
  font-size: 2.0em;
  word-wrap: break-word;
}
.video {
  max-width: 100%;
  text-align: center;
  overflow: hidden;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.free {
  padding-bottom: 2rem;
  padding-top: 0;
  margin-top: 2vw;
  margin-bottom: 1vw;
  letter-spacing: 0.05em;
  line-height: 1.8em;
  font-size: 20px;
  font-weight: 600;
}
.content-form {
  margin: 0 auto 1vw;
  width: 60%;
}
.content-form-caution {
  text-align: left;
  font-size: 0.7vw;
  margin: 0.4vw;
}
.conten-form-attention {
  text-align: left;
  font-size: 0.9vw;
  margin: 0.4vw;
}
.form-input-label {
  font-weight: bold;
}
#Usermail {
  margin: 1vw 0 1vw 3vw;
  height: 1.4vw;
  width: 18vw;
}
.submit-form-input-submit {
  font-weight: bold;
  width: 6vw;
  height: 2vw;
  border-radius: 6px;
  background-color: rgb(255, 240, 254);
  border: 1px solid rgb(211, 211, 211);
}
@media screen and (max-width: 640px) {
  .present {
  }
}
</style>
