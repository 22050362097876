<template>
  <div class="seminar">
    <div class="seminar-contents">
			<span class="list-title-category">定例セミナー</span>
      <div class="list-titles">
        <div class="list-title" @click="dispContents(value.id)" v-for="(value, key) in regular" :key="key">
					<div id="youtube">
						<iframe width="320" height="180" :src="'https://www.youtube.com/embed/' + value.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
					</div>
          <span>{{ value.title }}</span>
					<div id="date">開催日：{{ value.dateFmt }}</div>
        </div>
			</div>
			<span class="list-title-category">臨時セミナー</span>
      <div class="list-titles">
        <div class="list-title" @click="dispContents(value.id)" v-for="(value, key) in temporary" :key="key">
					<div id="youtube">
						<iframe width="320" height="180" :src="'https://www.youtube.com/embed/' + value.url" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
					</div>
          <span>{{ value.title }}</span>
					<div id="date">開催日：{{ value.dateFmt }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "../../main.js"
import { collection, getDocs } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  name: 'Seminar',
  components: {
  },
  props: {
  },
  data() {
    return {
      title: null,
      youtube: null,
      seminarDate: null,
      youtubeWidth: 640,
      youtubeHeight: 360,
      datas: [],
      regular: [],
      temporary: [],
      vars: {
        autoplay: 0
      },
      seminarContents: [],
    }
  },
  beforeCreate() {
    onAuthStateChanged(getAuth(), (user) => {
      if (!user) {
        this.$router.push("/member/signin");
      }
    });
  },
  mounted() {
    console.log("Seminar");

		document.getElementById("account").style.color = "black";
		document.getElementsByClassName("menu").forEach(x => {
			x.style.color = "black";
		});

		let height = window.innerHeight - document.getElementsByClassName("header")[0].clientHeight - document.getElementsByClassName("footer")[0].clientHeight - 30;
		if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
			document.getElementsByClassName("seminar")[0].style.height = height + "px";
		} else {
			document.getElementsByClassName("seminar")[0].style.height = height - 10 + "px";
		}

    this.onloadFunc();
  },
  methods: {
    async onloadFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "Seminar"));
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        data.dateFmt = data.date ? this.formatDate(data.date) : null;
        this.datas.push(data);
        if (data.category == "regular") {
          this.regular.push(data);
        } else if (data.category == "temporary") {
          this.temporary.push(data);
        }
      });
      this.regular.sort((a, b) => b.no - a.no);
      this.temporary.sort((a, b) => b.no - a.no);

      this.seminarContents = this.regular.concat();
      let firstSel = document.createElement("option");
      firstSel.value = "";
      this.seminarContents.unshift(firstSel);
    },
    dispContents(id) {
      let target = null;
      if (id) {
        target = this.datas.find(element => element.id == id);
      } else {
        target = this.datas.find(element => element.id == document.getElementById("select-seminar-contents").value);
      }
      if (target) {
        this.title = target.title;
        this.youtube = target.url;
        this.dateFmt = target.dateFmt;
        if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
          this.youtubeWidth = window.innerWidth - 20;
          this.youtubeHeight = this.youtubeWidth * 0.5625;
        }
        document.getElementById("overview").innerHTML = target.overview;
      }
    },
    changeSeminarType() {
      const selectType = document.getElementById("select-seminar-type").value;
      if (selectType == "regular-seminar") {
        this.seminarContents = this.regular.concat();
      } else if (selectType == "temporary-seminar") {
        this.seminarContents = this.temporary.concat();
      }
      let firstSel = document.createElement("option");
      firstSel.value = "";
      this.seminarContents.unshift(firstSel);
    },
    onReady() {
      this.$refs.youtube.stopVideo();
    },
    formatDate(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date.getFullYear() + "年"
          + (date.getMonth() + 1) + "月"
          + date.getDate() + "日";
    },
  }
}
</script>

<style scoped>
.seminar {
  width: 90%;
	margin: 5vw auto 0;
}
.seminar-contents {
  width: 100%;
	text-align: left;
}
.contents {
  width: 90%;
  margin-top: 0.6vw;
  margin-left: 1.6vw;
}
.list-titles {
  text-align: left;
  margin: 0 auto;
  width: 100%;
  display: inline-flex;
	display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.list-title-category {
  font-weight: bold;
  line-height: 2vw;
}
.list-title {
	width: 19vw;
	margin: 1vw 1.5vw 0;
}
#title {
  text-align: left;
  font-size: 1.4vw;
  font-weight: bold;
}
#date {
  margin: 0.5vw 0;
  text-align: left;
}
#overview {
  margin-top: 0.5vw;
  text-align: left;
  white-space: pre-line;
  height: 10vw;
  overflow-y: auto;
}
#youtube {
  margin-left: 0.6vw;
}
@media screen and (max-width: 640px) {
	.seminar {
		width: 100%;
		margin-top: 12vw;
		overflow-y: auto;
	}
  .seminar-contents {
    width: 100%;
    display: inline-grid;
  }
  .list-titles {
		display: grid;
  }
  .list-title {
		width: 90%;
		display: grid;
		margin: 6vw auto 0;
  }
  .list-titles-sp {
    display: grid;
    margin: 2vw auto;
  }
	.list-title-category {
    text-indent: 4vw;
	}
  .contents {
    width: 100%;
  }
  .select-seminar {
    width: 40vw;
    height: 7vw;
    margin-top: 2vw;
  }
  #title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  #overview {
    text-align: left;
    white-space: pre-line;
    overflow-wrap: anywhere;
    width: 80%;
    margin: 2vw auto;
  }
  #date {
    margin-top: 0vw;
    text-align: center;
    margin-bottom: 6vw;
  }
  #youtube {
    display: inline-grid;
    margin: 0 auto;
  }
}
</style>
