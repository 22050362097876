<template>
  <div class="member">
    <MemberHeader/>
  </div>
</template>

<script>
import firebase from "../../main.js"
import { collection, getDocs } from "firebase/firestore";

import MemberHeader from '../../components/MemberHeader.vue';

import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  name: 'Member',
  components: {
    MemberHeader
  },
  props: {
  },
  data() {
    return {
      news: []
    }
  },
  beforeCreate() {
    onAuthStateChanged(getAuth(), (user) => {
      if (!user) {
        this.$router.push("/member/signin");
      }
    });
  },
  mounted() {
    console.log("Member");

  },
  methods: {
    async onloadFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "News"));
      querySnapshot.forEach((doc) => {
        this.news.push(JSON.stringify(doc.data()));
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
