<template>
  <MemberHeader/>
  <vue-advanced-chat
    :current-user-id="currentUserId"
    :rooms="JSON.stringify(rooms)"
    :messages="JSON.stringify(messages)"
    :room-actions="JSON.stringify(roomActions)"
  />
</template>

<script>
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import { register } from 'vue-advanced-chat';

import MemberHeader from '../../components/MemberHeader.vue';
register()

// Or if you used CDN import
// window['vue-advanced-chat'].register()

export default {
  name: 'Chat',
  components: {
    MemberHeader
  },
  props: {
  },
  data() {
    return {
    currentUserId: '1234',
    rooms: [],
    messages: [],
    roomActions: [
        { name: 'inviteUser', title: 'Invite User' },
        { name: 'removeUser', title: 'Remove User' },
        { name: 'deleteRoom', title: 'Delete Room' }
    ]
    }
  },
  beforeCreate() {
    onAuthStateChanged(getAuth(), (user) => {
      if (!user) {
        this.$router.push("/member/signin");
      }
    });
  },
  mounted() {
    console.log("Chat");

  },
}
</script>
<style scoped>
vue-advanced-chat {
  margin-top: 1vw;
}
</style>