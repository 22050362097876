<template>
  <div class="member-header">
    <router-link class="menu" to="/member/seminar">
      過去のセミナー
    </router-link>
    <!-- <router-link class="menu" to="/member/chat">
      チャット
    </router-link> -->
    <router-link class="menu" to="/member/chatlog">
      チャットログ
    </router-link>
    <router-link class="menu" to="/member/radio">
      村内ラジオ
    </router-link>
    <!-- <router-link class="menu" to="/member/research">
      リサーチツール
    </router-link> -->
  </div>
</template>

<script>

export default {
  name: 'MemberHeader',
  props: {},
  mounted() {
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
  box-shadow: 0 1px 4px rgb(0 0 0 / 12%);
  margin-bottom: 1vw;
}
.logo {
  margin: 0 auto;
  padding: 0.5vw 0;
  width: 4vw;
  height: auto;
}
.logo img {
  position: relative;
  width: 100%;
}
.header-menu {
  padding-bottom: 0.5vw;
}
.menu {
  font-family: 'Kiwi Maru', cursive;
  margin: 0 2vw;
  font-size: 1.2vw;
  text-decoration: none;
  color: unset;
}
.member-menu-icon {
  width: 20px;
  height: auto;
  top: 2px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .menu {
    font-family: 'Kiwi Maru', cursive;
    margin: 0 2vw;
    font-size: 20px;
    text-decoration: none;
    color: unset;
  }
}
</style>
