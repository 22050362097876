<template>
  <cloud :data="words" :fontSizeMapper="fontSizeMapper" />
  <DIV id="cloud"></DIV>
  <div id="mining-chart" class="mining-chart">
    名詞
    <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="meishiData"
    />
    動詞
    <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="doushiData"
    />
    形容詞
    <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="keiyoushiData"
    />
    感動詞
    <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="kandoushiData"
    />
    <button @click="chartDisp">chart</button>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'MiningChart',
  components: { Bar },
  props: {
    chartOriginData : [],
    titles: String,
  },
  data() {
    return {
      meishiData: {
        labels: [],
        datasets: []
      },
      doushiData: {
        labels: [],
        datasets: []
      },
      keiyoushiData: {
        labels: [],
        datasets: []
      },
      kandoushiData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        indexAxis: 'y',
        responsive: true
      },
      words: [
        { text: 'Vue', value: 1000 },
        { text: 'js', value: 200 },
        { text: 'is', value: 800 },
        { text: 'very cool', value: 1000000 },
        { text: 'lunch', value: 100 },
      ],
      fontSizeMapper: word => Math.log2(word.value) * 5,
    }
  },
  mounted() {
    this.cloudDisp();
    
  },
  methods: {
    chartDisp() {
      let mLabel = [];
      let dLabel = [];
      let keLabel = [];
      let kaLabel = [];
      let mData = [];
      let dData = [];
      let keData = [];
      let kaData = [];
      for (let i = 0; i < this.chartOriginData.length; i++) {
        if (this.chartOriginData[i].pos == "名詞") {
          mLabel.push(this.chartOriginData[i].word);
          mData.push(this.chartOriginData[i].count);
        } else if (this.chartOriginData[i].pos == "動詞") {
          dLabel.push(this.chartOriginData[i].word);
          dData.push(this.chartOriginData[i].count);
        } else if (this.chartOriginData[i].pos == "形容詞") {
          keLabel.push(this.chartOriginData[i].word);
          keData.push(this.chartOriginData[i].count);
        } else if (this.chartOriginData[i].pos == "感動詞") {
          kaLabel.push(this.chartOriginData[i].word);
          kaData.push(this.chartOriginData[i].count);
        }
      }
      this.meishiData = {
        labels: mLabel,
        datasets: [ {data: mData } ]
      };
      this.doushiData.datasets = {
        labels: dLabel,
        datasets: [ {data: dData } ]
      };
      this.keiyoushiData.datasets = {
        labels: keLabel,
        datasets: [ {data: keData } ]
      };
      this.kandoushiData.datasets = {
        labels: kaLabel,
        datasets: [ {data: kaData } ]
      };
    }
  },
  watch: {
    titles() {
      this.chartDisp();
    }
  }
}
</script>

<style scoped>
.mining-chart {
  width: 30vw;
}
</style>
