<template>
  <div id="torihiki" class="torihiki">
    <div class="title">特定商取引法に基づく表記</div>
    <div class="content">
      ・統括者の名称、住所および連絡先<br>
      株式会社アチーブメントストラテジー社<br>
      〒5340027 大阪市都島区中野町３−４−２８<br>
      電話番号 06−7504−6135<br>
      FAX 06−7635−9670<br>
      <br>
      ・会員申込金<br>
      月会費（登録後7日後から課金） 1980円<br>
      月会費は、解約・退会まで毎月発生いたします<br>
      <br>
      ・代金の支払時期および方法<br>
      カード決済を基本とし毎月、初回申込の課金日に支払い<br>
      <br>
      ・解約・退会の規約<br>
      会員は、クーリングオフ期間経過後でも、会社に対する解約の意思表示により、将来に向かって契約を解約することができる。<br>
      <br>
      ・不良品の取扱条件<br>
      提供サービスの性質上、不良品は発生いたしません。
    </div>
  </div>
</template>

<script>

export default {
  name: 'Torihiki',
  mounted() {
    console.log("Torihiki");
  }
}
</script>

<style scoped>
.torihiki {
  margin: 0 auto;
  width: 35%;
  border: 0.5px solid rgb(223, 223, 223);
  text-align: left;
  padding: 3vw;
}
.title {
  font-size: 1.8rem;
  line-height: 2.3rem;
  font-weight: 300;
}
.content {
  margin-top: 2vw;
}
@media screen and (max-width: 640px) {
  .torihiki {
  }
}
</style>
