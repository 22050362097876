<template>
  <div class="member-regist">
    <div class="registed">
      <div class="registed-units">
        <div @click="deploymentData(value.id)" v-for="(value, key) in datas" :key="key">
          <div class="registed-unit">
            <div>姓名：{{ value.lastname }} {{ value.firstname }}</div>
            <div>メール：{{ value.email }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="regist">
      <table>
        <tr>
          <th>（開発者用）ID</th>
          <td><input class="textinput" type="text" id="dataid" name="dataid"></td>
        </tr>
        <tr>
          <th>姓</th>
          <td><input class="textinput" type="text" id="lastname" name="lastname"></td>
        </tr>
        <tr>
          <th>名</th>
          <td><input class="textinput" type="text" id="firstname" name="firstname"></td>
        </tr>
        <tr>
          <th>メール</th>
          <td><input class="textinput" type="text" id="email" name="email"></td>
        </tr>
        <tr>
          <th>登録日</th>
          <td><input class="textinput" type="datetime-local" id="startdate" name="startdate"></td>
        </tr>
        <tr>
          <th>削除フラグ</th>
          <td class="checkboxinput-td">
            <input class="checkboxinput" type="checkbox" name="deleteflg" id="deleteflg">
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <ion-icon class="input-ud-icon" @click="postData" name="save-outline"></ion-icon>
            <ion-icon class="input-ud-icon" @click="deleteData" name="trash-outline"></ion-icon>
            <ion-icon class="input-ud-icon" @click="clearData" name="reload-outline"></ion-icon>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import firebase from "../../main.js"
import { collection, doc, getDocs, addDoc, deleteDoc, setDoc } from "firebase/firestore";

export default {
  name: 'MemberRegist',
  props: {
  },
  data() {
    return {
      datas: [],
    }
  },
  mounted() {
    console.log("MemberRegist");

		document.getElementById("account").style.color = "black";
		document.getElementsByClassName("menu").forEach(x => {
			x.style.color = "black";
		});
    document.getElementById("footer").style.display = "none";

    this.onloadFunc();  
  },
  unmounted() {
    document.getElementById("footer").style.display = "grid";
  },
  methods: {
    awaitFunc() {
      this.datas = [];
      this.onloadFunc();
    },
    async onloadFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "Members"));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.date = data.date ? this.formatDate : '';
        this.datas.push(data);
      });
    },
    formatDate(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date.getFullYear() + "年"
          + (date.getMonth() + 1) + "月"
          + date.getDate() + "日 "
          + ("00" + date.getHours()).slice(-2) + ":"
          + ("00" + date.getMinutes()).slice(-2);
      
    },
    async postData() {
      const data = {
        lastname: document.getElementById("lastname").value.trim(),
        firstname: document.getElementById("firstname").value.trim(),
        email: document.getElementById("email").value.trim(),
        startdate: document.getElementById("startdate").value,
        deleteflg: document.getElementById("deleteflg").checked,
        updatedate: new Date()
      }

      const dataid = document.getElementById("dataid").value;
      if (dataid) {
        await setDoc(doc(firebase.db, "Members", dataid), data);
      } else {
        const db = collection(firebase.db, "Members");
        await addDoc(db, data);
      }

      document.getElementById("dataid").value = "";
      document.getElementById("lastname").value = "",
      document.getElementById("firstname").value = "",
      document.getElementById("email").value = "",
      document.getElementById("startdate").value = "",
      document.getElementById("deleteflg").checked = false,

      this.awaitFunc();

    },
    async deleteData() {
      const id = document.getElementById("dataid").value;
      if (id) {
        if (confirm("削除してもいいですか？")) {
          await deleteDoc(doc(firebase.db, "Members", id));
        }
        this.awaitFunc();
      }

      document.getElementById("dataid").value = "";
      document.getElementById("lastname").value = "";
      document.getElementById("firstname").value = "";
      document.getElementById("email").value = "";
      document.getElementById("startdate").value = "",
      document.getElementById("deleteflg").checked = false;
    },
    clearData() {
      document.getElementById("dataid").value = "";
      document.getElementById("lastname").value = "";
      document.getElementById("firstname").value = "";
      document.getElementById("email").value = "";
      document.getElementById("startdate").value = "",
      document.getElementById("deleteflg").checked = false;
    },
    deploymentData(id) {
      const data = this.datas.find(x => x.id == id);
      if (data) {
        document.getElementById("dataid").value = data.id;
        document.getElementById("lastname").value = data.lastname;
        document.getElementById("firstname").value = data.firstname;
        document.getElementById("email").value = data.email;
        document.getElementById("startdate").value = data.startdate,
        document.getElementById("deleteflg").checked = false;
      }
    }
  }
}
</script>

<style scoped>
.member-regist {
  display: inline-flex;
  margin: 2vw auto;
	width: 80%;
}
.conditions {
  display: inline-flex;
}
.sortdiv {
  margin: 0.6vw 0;
}
select {
  font-size: 0.8vw;
}
.dispanxietylabel,
.dispconfirmlabel {
  margin-left: 1vw;
  vertical-align: bottom;
}
.dispanxietyinput,
.dispconfirminput {
  width: 1vw;
  margin-top: 0.6vw;
  height: 1vw;
  margin-bottom: 0;
  vertical-align: inherit;
}
.registed {
  width: 22vw;
  text-align: left;
}
.registed-units {
  overflow: auto;
  height: 30vw;
}
.registed-unit {
  margin-bottom: 0.3vw;
  background-color: aliceblue;
}
.registed-unit-anxiety {
  margin-bottom: 0.3vw;
  background-color: bisque;
}
.textinput,
.textareainput {
  width: 100%;
}
.textinput {
  height: 1.6vw;
}
.textareainput {
  height: 8vw;
}
.checkboxinput-td {
  text-align: left;
}
.checkboxinput {
  width: 1.6vw;
  margin: 0;
  height: 1.6vw;
}
.input-ud-icon {
  width: 1.8vw;
  height: 1.8vw;
  margin: 0.4vw 0.8vw;
}
.regist {
	width: 50%;
}
.regist table {
  width: 100%;
}
.regist th {
  text-align: right;
  vertical-align: top;
  padding-right: 0.8vw;
}
.reference {
  text-align: left;
}
.dispcomment {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.back {
  text-align: left;
  padding-left: 1.6vw;
  text-decoration: none;
}
.back ion-icon {
  width: 1.6vw;
  height: 1.6vw;
  color: var(--color);
}
</style>
