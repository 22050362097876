<template>
  <div id="tokuten" class="tokuten">
  </div>
</template>

<script>

export default {
  name: 'Tokuten',
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log("Tokuten");
  },
  methods: {
  }
}
</script>

<style scoped>
.tokuten {
}
@media screen and (max-width: 640px) {
  .tokuten {
  }
}
</style>
