<template>
  <div id="privacy-policy" class="privacy-policy">
    <div class="title">プライバシーポリシー</div>
    <div class="pre">
      リンゴ村運営企業である株式会社アチーブメントストラテジー社は、お客様により良い商品・サービスをご提供するにあたり、 お客様の個人情報を適切に管理し、お客様のご要望に沿って利用することを 重要な債務であると考えています。<br>
      <br>
      お客様の個人情報の保護に万全を尽くすため、 株式会社アチーブメントストラテジー社は以下に定めるプライバシーポリシーを尊守することをここに宣言します。
    </div>    
    <div class="content">
      <p class="content-title">個人情報（プライバシー）の定義について</p>
      <p class="content-value">個人情報（プライバシー）とは、氏名・生年月日・住所・電話番号・電子メールアドレス・当社サイトへのアクセスログ等の、特定の個人を識別できる記録（電磁記録含む）の全てだと認識しております。</p>

      <p class="content-title">法令の尊守</p>
      <p class="content-value">株式会社アチーブメントストラテジー社は、個人情報を取り扱う際に、個人情報の保護に関する法律をはじめ個人情報保護に関する諸法令、および主務大臣のガイドラインに定められた義務、並びに本ポリシーを尊守します。</p>

      <p class="content-title">個人情報の利用目的</p>
      <p class="content-value">株式会社アチーブメントストラテジー社は、お客様の個人情報を次の目的のために必要な範囲で取得・利用します。</p>

      <p class="content-title">各種業務の継続・維持管理</p>
      <p class="content-value">株式会社アチーブメントストラテジー社とその関連会社・提携会社の各種商品やサービスのご案内・提供・維持管理<br><br>株式会社アチーブメントストラテジー社の業務に関する情報提供・運営管理、商品・サービスの充実</p>

      <p class="content-title">利用目的等の明示・公表</p>
      <p class="content-value">株式会社アチーブメントストラテジー社は、あらかじめ利用目的、共同利用者の範囲、お問い合わせ窓口等の必要な情報を明示し、同意を得たうえで個人情報を取得するよう努めます。</p>

      <p class="content-title">機微（センシティブ）情報の取り扱い</p>
      <p class="content-value">株式会社アチーブメントストラテジー社は、適切な事業運営を確保するために、お客様の同意に基づき業務遂行上必要な範囲で、お客様の機微（センシティブ）情報を取得・利用します。</p>

      <p class="content-title">未成年者の個人情報</p>
      <p class="content-value">株式会社アチーブメントストラテジー社は、15歳未満のお子様から個人情報を取得する可能性がある場合、保護者のご同意をいただきご提供くださるよう明示したうえで取得する等、未成年者の個人情報の取り扱いに関し、特別の配慮を行ないます。</p>

      <p class="content-title">安全管理措置</p>
      <p class="content-value">株式会社アチーブメントストラテジー社は、お預かりした個人情報を利用目的の範囲内で正確・最新の内容に保つよう努め、不正なアクセス、改ざん、漏えいなどから守るべく、現時点での技術水準に合わせた必要かつ適切な安全管理措置を講じます。</p>

      <p class="content-title">委託先の監督</p>
      <p class="content-value">株式会社アチーブメントストラテジー社は、お預かりした個人情報の処理を利用目的の範囲内で第三者に委託する場合があります。これらの第三者は、十分な個人情報のセキュリティ水準にあることを確認の上選定し、契約等を通じて、必要かつ適切な監督を行ないます。</p>

      <p class="content-title">第三者への提供</p>
      <p class="content-value">株式会社アチーブメントストラテジー社は、法令により例外として認められた場合を除き、ご本人の同意を得ることなく、取得時に明示した共同利用者以外の第三者に個人情報を提供しません。</p>

      <p class="content-title">開示等の求め</p>
      <p class="content-value">株式会社アチーブメントストラテジー社は、ご本人が自らの個人情報の開示、訂正、商品やサービスの紹介の停止、または消去などを希望される場合、ご本人であることを確認したうえで、法令の規定に基づき、すみやかに対応します。</p>

      <p class="content-title">苦情への対応</p>
      <p class="content-value">株式会社アチーブメントストラテジー社は、取り扱う個人情報につき、ご本人からの苦情に対し迅速かつ適切に取組み、そのための社内体制の整備を行ないます。</p>

      <p class="content-title">社内体制の継続的改善</p>
      <p class="content-value">株式会社アチーブメントストラテジー社は、個人情報保護の視点に基づき、今後も継続的に社内体制の強化・改善に努めてまいります。</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PrivacyPolicy',
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log("PrivacyPolicy");

    this.divReload();
    window.onload = ()=> {
      this.divReload();
    }
  },
  methods: {
    divReload() {
      let ringoHeight = window.innerHeight - document.getElementsByClassName("header")[0].clientHeight
                                           - document.getElementsByClassName("footer")[0].clientHeight;
      document.getElementById("privacy-policy").style.height = ringoHeight - 10 + "px";
    }
  }
}
</script>

<style scoped>
.privacy-policy {
  margin: 0 auto;
  width: 38%;
  border: 0.5px solid rgb(223, 223, 223);
  text-align: left;
  padding: 2vw;
  overflow-y: auto;
  color: var(--company-color);
}
.title {
  font-size: 1.8rem;
  line-height: 2.3rem;
  font-weight: 300;
  margin-bottom: 30px;
}
.pre {
  padding-bottom: 2rem;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: 0.05em;
    line-height: 1.8em;
    font-size: 1em;
}
.content-title {
  border-bottom: 3px solid #fc858b;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 4px 8px;
  padding-top: 0;
  margin-top: 2.3rem;
  margin-bottom: 1.6rem;
}
.content-value {
  padding-bottom: 2rem;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 0.05em;
  line-height: 1.8em;
  font-size: 1em;
}
@media screen and (max-width: 640px) {
  .privacy-policy {
    margin: 0 auto;
    width: 96%;
    border: 0.5px solid rgb(223, 223, 223);
    text-align: left;
    padding: 2vw;
    overflow-y: auto;
  }
  .pre {
    font-size: 0.8em;
  }
  .content-title {
    font-size: 1.2rem;
    margin-top: 0;
  }
  .content-value {
    font-size: 0.8em;
  }
}
</style>
