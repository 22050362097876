<template>
  <div id="user-policy" class="user-policy">
    <div class="title">「リンゴ村」サービス利用規約</div>
    <div class="pre">株式会社アチーブメントストラテジー社は、「リンゴ村」サービス利用規約(以下「本規約」という)を以下のとおり定める。</div>
    <div class="content">
      <p class="content-title">第1条(本規約の目的)</p>
      <p class="content-value">
        本規約は、利用者がサービス利用申込書で申し込み、株式会社アチーブメントストラテジー社が承諾した「リンゴ村」サービス(以下、「本サービス」という。)に適用されるものである。
      </p>
      <p class="content-title">第2条(用語の定義)</p>
      <p class="content-value">
        本規約において使用する次の用語は、以下の意味を有する。<br>
        1「リンゴ村」サービスとは、株式会社アチーブメントストラテジー社及び株式会社アチーブメントストラテジー社の提携会社がインターネット上において提供する、これらの会社が開発したyoutubeノウハウを提供するサービスを意味する。<br>
        2「利用者」とは、本サービスの提供を受ける法人もしくは団体または個人を意味する。<br>
        3「本契約」とは、利用者が本規約に基づき、サービス利用申込書により申込み、株式会社アチーブメントストラテジー社が承諾することにより成立する利用者・株式会社アチーブメントストラテジー社間の本サービス利用契約をいう。<br>
      </p>
      <p class="content-title">第3条(本契約の有効期間、更新、契約単位)</p>
      <p class="content-value">
        1本契約の有効期間は、サービス開始日より1カ月とする。<br>
        <br>
        2本契約は、有効期間終了の15日前までに、株式会社アチーブメントストラテジー社または利用者が解約の通知を行わない限り、さらに1カ月間自動更新するものとし、以降も同様とする。<br>
        <br>
        3利用者は、本契約1契約につき1法人、1団体ないし1個人の日本国内の1事業でのみ本サービスの利用が可能であることを承認する。
      </p>
      <p class="content-title">第4条(変更の届出)</p>
      <p class="content-value">
        利用者は、その所属組織名、氏名、住所等、サービス利用申込書記載の項目について変更があった場合は、すみやかにその旨を株式会社アチーブメントストラテジー社所定の方法により届け出るものとする。
      </p>
      <p class="content-title">第5条(本サービスの利用料金)</p>
      <p class="content-value">
        本サービスの利用料金は「サービス利用申込画面」記載のとおりとする。
      </p>
      <p class="content-title">第6条(本サービスのPR)</p>
      <p class="content-value">
        株式会社アチーブメントストラテジー社は、別途利用者との間で合意がない限りは、第三者に対し、同社が利用者へ本サービスを提供している事実を公表することができるものとし、利用者はこれを承諾するものとする。
      </p>
      <p class="content-title">第7条(知的財産権)</p>
      <p class="content-value">
        1本サービスに使用される知的財産権一切は、株式会社アチーブメントストラテジー社及び株式会社アチーブメントストラテジー社の提携会社が所有ないし保持するものであり、利用者はあくまでも本サービスの利用をするだけであり、ソフトウェア使用並びに貸与を受け、もしくは利用許諾を得るものではないことを承認する。
      </p>
      <p class="content-title">第8条(競合サービスの開発禁止)</p>
      <p class="content-value">
        利用者は、株式会社アチーブメントストラテジー社の事前の書面による承諾なく、本契約の有効期間中、本サービスと同一または類似の目的を有するサービスにつき、直接間接を問わず、開発ないし開発に関与してはならない。また、競合製品の開発または販売を行う者に対する情報提供を目的とした各種調査にも関与してはならない。利用者は、故意または過失により本条に違反する行為を行った場合、その理由の如何を問わず、株式会社アチーブメントストラテジー社に対し、その損害を賠償する。なお、本条にいう損害には、本条に違反する行為により利用者又は第三者が得た利益が含まれるものとする。
      </p>
      <p class="content-title">第9条(利用料金の請求、及び支払方法)</p>
      <p class="content-value">
        1株式会社アチーブメントストラテジー社は、申込日から7日後から1ヶ月の期間を、<br>
        1対象月として、「サービス利用申込」記載の方法に従い、月額サービス利用料金を計算する。<br>
        <br>
        2利用者が、本契約第3条の規定に関わらず、本契約の有効期間内で途中解約する場合、利用者は、当該解約日から本契約の有効期間満了日までのサービス利用料金を当該解約日の翌月末日までに株式会社アチーブメントストラテジー社へ支払うものとする。<br>
        <br>
        3利用者は、株式会社アチーブメントストラテジー社に対し、利用料金を支払う場合、当該利用料金の額に消費税相当額を加算して支払う。<br>
        <br>
        4利用者が利用料金の支払その他、株式会社アチーブメントストラテジー社に対する債務を遅延した場合は、株式会社アチーブメントストラテジー社は、本サービスの提供を一時的に停止するとともに、利用者に対し、当該未払債務(消費税含む)に対して年率14.6%の割合による遅延損害金を請求するものとし、利用者はこれを異議なく支払う。<br>
        <br>
        5株式会社アチーブメントストラテジー社は、利用者に対し、利用料金に関して疑義が生じた場合、株式会社アチーブメントストラテジー社が必要と考える資料の提出を要求することができるものとし、利用者はこれを拒否しない。<br>
        <br>
        6利用者は、株式会社アチーブメントストラテジー社にその責がある場合を除き、理由の如何を問わず、受領済みの利用料金の払戻しを行わない。ただし、単純な計算過誤の場合はその限りではない。
      </p>
      <p class="content-title">第10条(禁止事項、利用の停止)</p>
      <p class="content-value">
        利用者は、本サービスの利用にあたって次の各号に該当する事項(以下「禁止事項」という)を行ってはならない。株式会社アチーブメントストラテジー社は、利用者が禁止事項を行ったことを発見した場合には、利用者に事前に通知及び勧告することなく、本サービスの利用を停止することができる。また、株式会社アチーブメントストラテジー社は、利用者に対し、禁止事項により損害を被ったときは、損害賠償を求めることができる。<br>
        <br>
        (1)本サービスの提供を受ける権利を他人に譲渡する行為。<br>
        (2)日本の法律に反する違法行為が行われた場合。<br>
        (3)他の利用者または第三者に損失または損害を与える行為。<br>
        (4)人権を侵害する行為、またはそのおそれのある行為。<br>
        (5)誹謗、中傷など、公序良俗に反する行為、またはそのおそれのある行為。<br>
        (6)犯罪的行為、犯罪的行為に結びつく行為、またはそのおそれのある行為。<br>
        (7)本サービスの運営を妨げる行為、またはそのおそれのある行為。<br>
        (8)コンピュータウィルス等有害なプログラムを本サービスを通じて、もしくは本サービスに関連して使用し、または提供する行為。<br>
        (9)本サービスの利用で知り得た、株式会社アチーブメントストラテジー社及び第三者の営業秘密を漏洩する行為。<br>
        (10)株式会社アチーブメントストラテジー社が判断した公序良俗に反するサービスに利用する行為。<br>
        (11)第三者または株式会社アチーブメントストラテジー社の著作権、その他の権利を侵害する行為または侵害するおそれのある行為。<br>
        (12)本規約のいずれかに違反する行為。<br>
        (13)その他、株式会社アチーブメントストラテジー社が利用者として不適切と判断する行為。<br>
        <br>
        2.利用者が前項で禁止する行為を行った場合、その行為に関わる責任は利用者が負うものとし、株式会社アチーブメントストラテジー社は一切の責任を負わない。
      </p>
      <p class="content-title">第11条(反社会的勢力の排除)</p>
      <p class="content-value">
        1株式会社アチーブメントストラテジー社及び利用者は、相手方が次の各号に該当する場合、何らの催告なしに本契約を解除することができるものとする。<br>
        (1)暴力団、暴力団構成員、準構成員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、政治活動標ぼうゴロ、特殊知能暴力集団、その他の反社会的勢力(以下「反社会的勢力」という)である場合、または反社会的勢力であった場合。<br>
        (2)自らまたは第三者を利用して、株式会社アチーブメントストラテジー社または利用者に対して、詐術、暴力的行為または脅迫的言辞を用いるなどした場合。<br>
        (3)株式会社アチーブメントストラテジー社または利用者に対して、自身が反社会的勢力である旨を伝え、または、関係団体もしくは関係者が反社会的勢力である旨を伝えるなどした場合。<br>
        (4)自らまたは第三者を利用して、株式会社アチーブメントストラテジー社または利用者の名誉や信用等を毀損し、または、毀損するおそれのある行為をした場合。<br>
        (5)自らまたは第三者を利用して、株式会社アチーブメントストラテジー社または利用者の業務を妨害した場合、または、妨害するおそれのある行為をした場合。<br>
        <br>
        2株式会社アチーブメントストラテジー社または利用者は、前項により本契約を解除した場合、相手方に損害が生じたとしても、一切の損害賠償を負担しない。
      </p>
      <p class="content-title">第12条(損害賠償)</p>
      <p class="content-value">
        1利用者が、本サービスの利用に関し、株式会社アチーブメントストラテジー社または第三者に損害を及ぼした場合、利用者は、株式会社アチーブメントストラテジー社または当該第三者に対し、かかる損害を賠償するものとする。<br>
        <br>
        2利用者は、本サービスの利用に関しての第三者からのクレームについて、株式会社アチーブメントストラテジー社を免責するものとする。但し、株式会社アチーブメントストラテジー社に責ある場合を除く。<br>
        <br>
        3利用者は、第三者から本サービスに関連する知的財産権侵害のクレームがなされた場合は、直ちにその旨を株式会社アチーブメントストラテジー社に報告する。<br>
        <br>
        4株式会社アチーブメントストラテジー社がその責に帰すべき事由が原因となって、利用者に対して何らかの損害賠償責任を負う場合、その損害賠償の金額は、その損害賠償責任の原因事由が発生した時点から過去に遡って、株式会社アチーブメントストラテジー社が利用者から現実に受領した3か月分の利用料金の合計額を上限とする。なお、損害賠償の範囲は、利用者に直接生じた損害に限定され、間接損害や逸失利益は含まれないものとする。<br>
        <br>
        5利用者は、本条に拘わらず、通信障害による速度の低下及びデータ損壊等による損害、及び第三者の不正な手段による接続に起因する情報の破損、改ざん、及び漏洩等による損害につき株式会社アチーブメントストラテジー社を免責する。<br>
        <br>
        6株式会社アチーブメントストラテジー社は、株式会社アチーブメントストラテジー社の責に帰すべき事由により本サービスに不具合が生じた場合も、利用者に対する本サービスの提供が24時間以上停止された場合に限り本条に従い責任を負うものとする。
      </p>
      <p class="content-title">第13条(本契約の解除)</p>
      <p class="content-value">
        株式会社アチーブメントストラテジー社または利用者は、相手方に、次の事項のいずれかに該当する事由が生じた場合には、事前の通知及び勧告することなく、本契約を解除することができる。この場合、解除を行った株式会社アチーブメントストラテジー社または利用者は、相手方に対し、違約金や損害賠償等の責を一切負わない。<br>
        <br>
        (1)本契約に基づき発生した債務の全部または一部について不履行があり、相当の期間を定めた催告を受けたにもかかわらず、当該期間内に履行しないとき。<br>
        (2)第11条1項に定める禁止事項を行ったとき。<br>
        (3)監督官庁より営業取消、停止等の処分を受けたとき。<br>
        (4)第三者より仮差押、仮処分または強制処分を受け、契約の履行が困難と認められるとき。<br>
        (5)破産、任意整理、特別清算、民事再生手続開始、会社更生手続開始等の申立があったとき。<br>
        (6)解散の決議をしたとき。<br>
        (7)その他、株式会社アチーブメントストラテジー社が利用者の契約継続を適当でないと判断した場合。
      </p>
      <p class="content-title">第14条(本サービスの終了)</p>
      <p class="content-value">
        1株式会社アチーブメントストラテジー社は、技術的理由その他の都合により本サービスを終了することができる。<br>
        <br>
        2株式会社アチーブメントストラテジー社は、利用者に対し、本サービスを終了するときは、終了する日の30日前までに、電子メール等にてその旨を通知する。なお、本サービスの終了によって発生した利用者の損害について、株式会社アチーブメントストラテジー社は一切責任を負わない。
      </p>
      <p class="content-title">第15条(秘密の保持)</p>
      <p class="content-value">
        1本契約において「秘密情報」とは、一方当事者が秘密であることを明示して文書、口頭またはその他の方法により相手方に開示する一切の技術情報、ノウハウ、データ及び営業上の一切の情報ならびに株式会社アチーブメントストラテジー社が提供するサンプルやソフトウェア、株式会社アチーブメントストラテジー社に送信される利用者及び閲覧者のデータを意味する。但し、<br>
        1開示時に、既に受領者が自ら保有していたもの、または第三者から秘密保持の義務を負うことなく適法に入手していたことが証明された情報、<br>
        2開示時に、既に公知であった情報、<br>
        3開示後に、受領者の過失によらず、公知となった情報、<br>
        4開示の前後を問わず、受領者が本契約に違反することなく独自に開発したことが証明された情報、<br>
        5開示後に、受領者が正当な権限を持つ第三者から適法に入手した情報、<br>
        6開示者が文書により秘密でない旨通知した情報、<br>
        7開示時に、開示者が本契約と同様の制限をすることなく第三者に提供している情報については、秘密情報とはみなさない。<br>
        <br>
        2利用者は、本契約で規定された利用料金など特約事項に関して、株式会社アチーブメントストラテジー社の書面の合意なしには、第三者に開示または漏洩してはならない。<br>
        <br>
        3株式会社アチーブメントストラテジー社及び利用者は、秘密情報が第三者に使用されまたは開示されないように可能な一切の処置を取るものとする。また、相手方から開示された秘密情報を、知る必要のある役職員に対してのみ開示することができるものとする。但し、当該役職員に対して本契約に定める秘密保持義務を通知するものとし、当該役職員に当該義務を遵守させるものとする。<br>
        <br>
        4本サービスの提供に関して知りえた互いの秘密情報を、株式会社アチーブメントストラテジー社及び利用者は如何なる第三者に対しても相手方の書面の合意なしには、提供、開示または漏洩できないものとする。<br>
        <br>
        5法令の根拠に基づき行政機関等から開示を求められた場合は、秘密情報を開示することができる。但し、その場合、速やかにその旨を相手方に通知しなければならない。<br>
        <br>
        6株式会社アチーブメントストラテジー社は、情報が利用者を特定しない、あるいは、他の利用者のデータと合算することを前提として、本サービスの効果及び利用状況を説明するサマリー情報を開示することができる。<br>
        <br>
        7株式会社アチーブメントストラテジー社及び利用者は、相手方当事者が本条に違反した場合、相手方当事者に対し損害賠償を請求することができる。<br>
        <br>
        8株式会社アチーブメントストラテジー社及び利用者は、相手方当事者から要求があった場合または本契約が終了した場合、両者が別に定めた場合を除き、一切の秘密情報もしくは秘密情報を含むソフトウェア、データ、文書、物品等及びその複写、複製物の全部を廃棄するかまたは遅滞なく相手方当事者に返還しなければならない。
      </p>
      <p class="content-title">第16条(契約譲渡の禁止)</p>
      <p class="content-value">
        株式会社アチーブメントストラテジー社及び利用者は、相手方の書面による事前同意なくして、本契約上の権利を第三者に譲渡、再許諾し、あるいは担保に供してはならず、または第三者に義務を承継できないものとする。
      </p>
      <p class="content-title">第17条(保証の制限)</p>
      <p class="content-value">
        1本サービスに関わる如何なるソフトウェア、文書、サービスは、本契約に特に記載されている場合を除いて、商品性、特定目的への適合性、または権利の非侵害性に関して如何なる表明及び保証をするものではない。<br>
        <br>
        2利用者は、自己の責任で本サービスを利用するものとし、本サービスを利用することが、利用者に適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとする。株式会社アチーブメントストラテジー社は利用者による本サービスの利用が、利用者に適用のある法令、業界団体の内部規則等に適合することを何ら保証しない。<br>
        <br>
        3.本サービスが第三者の提供するクラウドサービスに依拠して株式会社アチーブメントストラテジー社が提供するものである場合、当該クラウドサービスの中断や不具合等の影響により、本サービスの提供に支障・不具合を来したとしても、株式会社アチーブメントストラテジー社は利用者に対し責任を負わないことを確認する。
      </p>
      <p class="content-title">第18条(契約終了後の処理)</p>
      <p class="content-value">
        第13条、第16条、第18条及び第20条の規定は、本契約が解除された後、または本契約の有効期間が終了した後も有効に存続するものとする。
      </p>
      <p class="content-title">第19条(管轄裁判所)</p>
      <p class="content-value">
        本契約に関わる紛争については、大阪地方裁判所を第一審の専属的合意管轄裁判所とする。
      </p>
      <p class="content-title">第20条(協議解決)</p>
      <p class="content-value">
        本規約の解釈に疑義が生じた場合、または本契約に定めのない事項については、株式会社アチーブメントストラテジー社及び利用者は誠意をもって協議し、解決するものとする。
      </p>
      <p class="content-title">第21条(本規約の改定)</p>
      <p class="content-value">
        株式会社アチーブメントストラテジー社は、本規約を任意に改定ないし補充できるものとする。本規約の改定ないし補充は、改定後の本規約を株式会社アチーブメントストラテジー社所定のサイトに掲示したときにその効力を生じるものとし、利用者は、改定後の規約に従うものとする。
      </p>
      <p class="content-title">第22条(附則)</p>
      <p class="content-value">
        2021年7月30日制定:施行
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserPolicy',
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
    console.log("UserPolicy");
    let height = window.innerHeight - document.getElementsByClassName("header")[0].clientHeight - document.getElementsByClassName("footer")[0].clientHeight;
    document.getElementsByClassName("user-policy")[0].style.height = (height * 0.85) + "px";
  },
  methods: {
  }
}
</script>

<style scoped>
.user-policy {
  margin: 0 auto;
  width: 40%;
  border: 0.5px solid rgb(223, 223, 223);
  text-align: left;
  padding: 2vw;
  overflow-y: auto;
  color: var(--company-color);
}
.title {
  font-size: 1.8rem;
  line-height: 2.3rem;
  font-weight: 300;
  margin-bottom: 30px;
}
.pre {
  padding-bottom: 2rem;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: 0.05em;
    line-height: 1.8em;
    font-size: 1em;
}
.content-title {
  border-bottom: 3px solid #fc858b;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 4px 8px;
  padding-top: 0;
  margin-top: 2.3rem;
  margin-bottom: 1.6rem;
}
.content-value {
  padding-bottom: 2rem;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 0.05em;
  line-height: 1.8em;
  font-size: 1em;
}
@media screen and (max-width: 640px) {
  .user-policy {
  }
}
</style>
