<template>
  <div id="musicQA" class="musicQA noto-sans-jp-400">
		<div class="header">
			<span id="title">BGM収益化サービス QAページ</span>
			<img src="../../assets/site_images/ringo.png" alt="">
		</div>
		<div class="explanation">
			お問い合わせフォームです。<br>
			下記に質問内容を記入し、「送信」ボタンを押してください。<br>
			<br>
			あわせて、QA一覧もご覧ください。
		</div>
		<div class="form">
			<table>
				<tr>
					<td class="table-title">お名前</td>
					<td><input type="text" v-model="name"></td>
				</tr>
				<tr>
					<td class="table-title">メールアドレス</td>
					<td><input type="text" v-model="mail"></td>
				</tr>
				<tr>
					<td class="table-title">お問い合わせ内容</td>
					<td><textarea name="inquiry" v-model="inquiry" id="" cols="30" rows="10"></textarea></td>
				</tr>
				<tr>
					<td colspan="2">
						<div>
							<button class="clear" @click="clear">クリア</button>
							<button class="submit" @click="submit">登録</button>
						</div>
						<div v-if="submited" class="submit-content">
							送信されました。 お問い合わせありがとうございました。
						</div>
					</td>
				</tr>
			</table>
		</div>
		<div v-for="category in categories" :key="category">
			<div class="categoryTitle">{{category.category}}</div>
			<div class="pre-qas" v-for="qa in qas" :key="qa">
				<div class="toggleable" v-if="category.category == qa.category">
					<input class="toggleable__control hidden--visually" :id="'toggleable' + qa.id" type="checkbox" />
					<label class="toggleable__label" :for="'toggleable' + qa.id">
						Q. {{ qa.question }}
					</label>
					<div style="white-space: pre-wrap;text-indent: -17px;" class="toggleable__content" v-text="'A. ' + qa.answer"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import firebase from "../../main.js"
import { collection, getDocs } from "firebase/firestore";

export default {
  name: 'MusicQA',
  props: {
  },
  data() {
    return {
			submited: false,
			name: null,
			mail: null,
			inquiry: null,
			webhook_url: "https://discord.com/api/webhooks/1252245613949620245/b_rlMpqSgNsLdXwtSNXFufloPjLpGxot3G-pDRUgT6BytNwMCRXWGPUV4vlNuoMX2qHf",
			categories: [],
			qas: []
    }
  },
  mounted() {
    console.log("MusicQA");
    document.getElementById("header").style.display = "none";
    this.onloadFunc();  
  },
  methods: {
    async onloadFunc() {
      const mqacQuerySnapshot = await getDocs(collection(firebase.db, "MusicQACategories"));
      mqacQuerySnapshot.forEach((mqac) => {
        let data = mqac.data();
        data.id = mqac.id;
        this.categories.push(data);
      });
      const mqaQuerySnapshot = await getDocs(collection(firebase.db, "MusicQAs"));
      mqaQuerySnapshot.forEach((mqa) => {
        let data = mqa.data();
        data.id = mqa.id;
        this.qas.push(data);
      });

			this.categories.sort((a, b) => a.number - b.number);
			this.qas.sort((a, b) => a.number - b.number);
    },
		clear() {
			this.name = null;
			this.mail = null;
			this.inquiry = null;
		},
		submit() {
			const data = {
				content: "BGM問い合わせがありました。\n氏名：" + this.name + "\nメールアドレス：" + this.mail + "\n内容：" + this.inquiry
			};
			axios.post(this.webhook_url, data).then(() => {
				this.submited = true;
				setTimeout(this.falseMsg, 3000);
			});
		},
		falseMsg() {
			this.name = null;
			this.mail = null;
			this.inquiry = null;
			this.submited = false;
		}
	}
}
</script>

<style scoped>
.musicQA {
  width: 80%;
  margin: 1vw auto 4vw;
}
.toggleable__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .8s cubic-bezier(0,1,0,1);
	text-align: left;
}
.toggleable__control:checked ~ .toggleable__content {
  transition-timing-function: ease-in-out;
  max-height: 800px;
}
/* Hiding elements in an accessible way */
.hidden--visually {
    border: 0;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
/* Theming CSS */
body {
  max-width: 600px;
  padding: 3rem 1rem;
  font-size: 1.25rem;
  line-height: 1.333;
  font-family: sans-serif;
  margin: 0 auto;
}
.toggleable {
  border-bottom: 2px solid lightgray;
}
.toggleable__label {
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem 0;
  cursor: pointer;
}
.toggleable__content {
  margin-top: 1rem;
  padding: 0 2rem 0 4rem;
}
.toggleable__control:checked ~ .toggleable__content {
  margin-bottom: 1rem;
}
.toggleable__label::after {
  content: "▼";
    font-size: 0.8rem;
}
.toggleable__control:checked ~ .toggleable__label::after {
  content: "▲";
}
.pre-qas {
	width: 60%;
	margin: 0 auto;
}
table {
	margin: 2vw auto;
}
.table-title {
	text-align: right;
	line-height: 2em;
	padding-right: 1vw;
}
input {
	width: 16vw;
	height: 2em;
	float: left;
}
textarea {
	width: 25vw;
	height: 12vw;
}
button {
	padding: 0.4vw 1vw;
	margin: 0 0.4vw;
	border-radius: 5px;
	border: 1px solid black;
	font-weight: bold;
	cursor: pointer;
}
.clear {
	background-color: antiquewhite;
}
.submit {
	background-color: lightskyblue;
}
.submit-content {
	color: green;
}
.header {
	display: inline-grid;
	font-family: "RocknRoll One", sans-serif;
  font-weight: 400;
  font-style: normal;
	font-size: 2.5rem;
}
.header img {
	width: 4vw;
	height: auto;
	margin: 0 auto 2vw;
}
.categoryTitle {
	font-size: 1.2rem;
	line-height: 2vw;
	font-weight: bold;
	background-color: #ebebeb;
	height: 2vw;
	border-radius: 10px;
	width: 60%;
	margin: 2vw auto 0.5vw;
}
@media screen and (max-width: 640px) {
}
</style>
