<template>
  <div class="seminar-regist">
    <div class="registed">
      <div class="registed-units">
        <div @click="deploymentData(value.id)" v-for="(value, key) in displaydatas" :key="key">
          <div :id="value.id" class="registed-unit-common registed-unit">
            <div>タイトル：{{ value.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="regist">
      <table>
        <tr>
          <th>（開発者用）ID</th>
          <td class="reference"><span id="dataid"></span></td>
        </tr>
        <tr>
          <th>カテゴリ</th>
          <td>
            <select name="category" id="category">
              <option value="regular">定期セミナー</option>
              <option value="temporary">臨時セミナー</option>
            </select>
          </td>
        </tr>
        <tr>
          <th>No</th>
          <td><input class="textinput" type="number" id="no" name="no"></td>
        </tr>
        <tr>
          <th>日付</th>
          <td><input class="textinput" type="text" id="date" name="date"></td>
        </tr>
        <tr>
          <th>タイトル</th>
          <td><input class="textinput" type="text" id="title" name="title"></td>
        </tr>
        <tr>
          <th>URL</th>
          <td><input class="textinput" type="text" id="url" name="url"></td>
        </tr>
        <tr>
          <th>概要</th>
          <td><textarea class="comment" name="overview" id="overview" cols="30" rows="10"></textarea></td>
        </tr>
        <tr>
          <td colspan="2">
            <ion-icon class="input-ud-icon" @click="postData" name="save-outline"></ion-icon>
            <ion-icon class="input-ud-icon" @click="deleteData" name="trash-outline"></ion-icon>
            <ion-icon class="input-ud-icon" @click="clearData" name="reload-outline"></ion-icon>
          </td>
        </tr>
      </table>
      
    </div>
  </div>
</template>

<script>
import firebase from "../../main.js"
import { collection, doc, getDocs, addDoc, deleteDoc, setDoc } from "firebase/firestore";

export default {
  name: 'SeminarRegist',
  props: {
  },
  data() {
    return {
      datas: [],
      displaydatas: [],
    }
  },
  mounted() {
    console.log("SeminarRegist");

		document.getElementById("account").style.color = "black";
		document.getElementsByClassName("menu").forEach(x => {
			x.style.color = "black";
		});
    document.getElementById("footer").style.display = "none";

    this.onloadFunc();  
  },
  unmounted() {
    document.getElementById("footer").style.display = "grid";
  },
  methods: {
    awaitFunc() {
      this.datas = [];
      this.onloadFunc();
    },
    async onloadFunc() {
      const querySnapshot = await getDocs(collection(firebase.db, "Seminar"));
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        if (data.date) {
          data.dateFmt = this.formatDate(data.date);
        } else {
          data.dateFmt = "";
        }
        this.datas.push(data);
      });
      this.displaydatas = this.datas.concat();
    },
    formatDate(datetime) {
      var date = new Date(1970, 0, 1);
      date.setSeconds(datetime.seconds);
      date.setHours(date.getHours() + 9);
      return date.getFullYear() + "年"
          + (date.getMonth() + 1) + "月"
          + date.getDate() + "日";
      
    },
    async postData() {
      let cd = document.getElementById("date").value;
      let date = "";
      if (cd) {
        let yearSplit = cd.split("年");
        let monthSplit = yearSplit[1].split("月");
        let daySplit = monthSplit[1].split("日");

        let year = yearSplit[0].trim();
        let month = parseInt(monthSplit[0].trim()) - 1;
        let day = daySplit[0].trim();
        let hour = 0;
        let time = 0;

        date = new Date(year, month, day, hour, time);
      }
      
      const data = {
        no: document.getElementById("no").value,
        date: date,
        category: document.getElementById("category").value,
        overview: document.getElementById("overview").value,
        title: document.getElementById("title").value,
        url: document.getElementById("url").value
      }

      const id = document.getElementById("dataid").innerText;
      if (id) {
        await setDoc(doc(firebase.db, "Seminar", id), data);
      } else {
        const db = collection(firebase.db, "Seminar");
        await addDoc(db, data);
      }

      document.getElementById("dataid").innerText = "";
      document.getElementById("no").value = "";
      document.getElementById("category").value = "";
      document.getElementById("date").value = "";
      document.getElementById("overview").value = "";
      document.getElementById("title").value = ""
      document.getElementById("url").value = ""

      this.awaitFunc();

    },
    async deleteData() {
      const id = document.getElementById("dataid").innerText;
      if (id) {
        if (confirm("削除してもいいですか？")) {
          await deleteDoc(doc(firebase.db, "Seminar", id));
        }
        this.awaitFunc();
        alert("削除しました");
      }

      document.getElementById("dataid").innerText = "";
      document.getElementById("no").value = "";
      document.getElementById("category").value = "";
      document.getElementById("date").value = "";
      document.getElementById("overview").value = "";
      document.getElementById("title").value = ""
      document.getElementById("url").value = ""

    },
    clearData() {
      document.getElementById("dataid").innerText = "";
      document.getElementById("no").value = "";
      document.getElementById("category").value = "";
      document.getElementById("date").value = "";
      document.getElementById("overview").value = "";
      document.getElementById("title").value = ""
      document.getElementById("url").value = ""
    },
    deploymentData(id) {
      const data = this.displaydatas.find(x => x.id == id);
      if (data) {
        document.getElementById("dataid").innerText = data.id;
        document.getElementById("no").value = data.no;
        document.getElementById("category").value = data.category;
        document.getElementById("date").value = data.dateFmt;
        document.getElementById("overview").value = data.overview;
        document.getElementById("title").value = data.title;
        document.getElementById("url").value = data.url;
      }
    }
  }
}
</script>

<style scoped>
.oikora {
  margin-bottom: 1vw;
}
.seminar-regist {
  display: inline-flex;
  margin: 2vw auto;
  width: 80%;
}
select {
  font-size: 1vw;
  height: 1.8vw;
  width: 100%;
}
.registed {
  width: 50%;
  text-align: left;
  display: inline-grid;
}
.registed-units {
  overflow: auto;
  height: 27vw;
}
.registed-unit {
  margin-bottom: 0.3vw;
  background-color: aliceblue;
}
.registed-unit-anxiety {
  margin-bottom: 0.3vw;
  background-color: bisque;
}
.registed-unit-comment {
  margin-bottom: 0.3vw;
  background-color: mintcream;
}
.registed-unit-deploy {
  background-color: #ffbebe !important;
}

.textinput, .comment, .register-comment {
  width: 100%;
}
.textinput {
  height: 1.3vw;
}
.comment {
  height: 7vw;
}
.register-comment {
  height: 4vw;
}
.checkboxinput-td {
  text-align: left;
}
.checkboxinput {
  width: 1.6vw;
  margin: 0;
  height: 1.6vw;
}
.input-ud-icon {
  width: 1.8vw;
  height: 1.8vw;
  margin: 0.4vw 0.8vw;
}
.regist {
  width: 50%;
}
.regist table {
  width: 100%;
  border-spacing: 0;
}
.regist th {
  text-align: right;
  vertical-align: top;
  padding-right: 0.8vw;
}
.reference {
  text-align: left;
}
.dispcomment {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.back {
  text-align: left;
  padding-left: 1.6vw;
  text-decoration: none;
}
.back ion-icon {
  width: 1.6vw;
  height: 1.6vw;
  color: var(--color);
}
</style>
