<template>
  <div class="account">
    <div class="caution">
      ※現時点では参照のみです。（今後、チャット機能等実装するタイミングで再整理します）
    </div>
    <table>
      <tr>
        <th>名前：</th>
        <td>
          <input type="text" class="name" id="lastname" :value="lastname" disabled>
          <input type="text" id="firstname" :value="firstname" disabled>
        </td>
      </tr>
      <tr>
        <th>email：</th>
        <td style="text-align: left;">{{ email }}</td>
      </tr>
      <tr>
        <th>色：</th>
        <td style="text-align: left;"><input type="color" id="colorBox" @change="setColor" :value="color"></td>
      </tr>
      <tr>
        <th>ダークモード：</th>
        <div class="switchArea">
          <input type="checkbox" id="switch1">
          <label for="switch1"><span></span></label>
          <div id="swImg"></div>
        </div>
      </tr>
    </table>
  </div>
</template>

<script>
import firebase from "../../main.js"
import { logEvent } from "@firebase/analytics";
import { doc, getDoc, setDoc } from "@firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

export default {
  name: 'Account',
  components: {
  },
  props: {
  },
  data() {
    return {
      userUid: null,
      data: null,
      lastname: null,
      firstname: null,
      email: null,
      color: null,
    }
  },
  mounted() {
    console.log("Account");

    document.getElementsByClassName("account-menu")[0].style.display = "none";

    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        this.userUid = user.uid;
        logEvent(firebase.analytics, 'account', { email: user.email });
        this.onloadFunc();
      }
    });

  },
  methods: {
    async onloadFunc() {
      const ref = doc(firebase.db, "Members", this.userUid);
      const snapshot = await getDoc(ref);
      if (snapshot.exists()) {
        let data = snapshot.data();
        this.data = JSON.stringify(data);
        this.lastname = data.lastname;
        this.firstname = data.firstname;
        this.email = data.email;
        this.color = 'color' in data ? data.color : "#fcba03";
      }
    },
    async setColor(e) {
      let data = JSON.parse(this.data);
      data.color = e.target.value;
      await setDoc(doc(firebase.db, "Members", this.userUid), data);
    }
  }
}
</script>

<style scoped>
.updatepassword {
  margin: 0 auto;
}
table {
  margin: 2vw auto;
}
th {
  font-family: 'Kiwi Maru', cursive;
  text-align: right !important;
  height: 4vw;
  padding-right: 1vw;
}
td {
  text-align: left;
}
input {
  height: 1.4vw;
  width: 10vw;
  margin-right: 2vw;
  background-color: rgb(240, 240, 240);
  border: 1px solid;
  padding-left: 0.2vw;
}
.caution {
  color: red;
}
button {
  font-family: 'Kiwi Maru', cursive;
  width: 6vw;
  height: 1.8vw;
  font-size: 0.8vw;
  border-radius: 10px;
  border: none;
  background-color: rgb(253, 219, 225);
}
#colorBox {
  width: 4vw;
}
/* === ボタンを表示するエリア ============================== */
.switchArea {
  line-height    : 60px;                /* 1行の高さ          */
  letter-spacing : 0;                   /* 文字間             */
  text-align     : center;              /* 文字位置は中央     */
  font-size      : 27px;                /* 文字サイズ         */

  position       : relative;            /* 親要素が基点       */
  margin         : auto;                /* 中央寄せ           */
  width          : 150px;               /* ボタンの横幅       */
  background     : #fff;                /* デフォルト背景色   */
}

/* === チェックボックス ==================================== */
.switchArea input[type="checkbox"] {
  display        : none;            /* チェックボックス非表示 */
}

/* === チェックボックスのラベル（標準） ==================== */
.switchArea label {
  display        : block;               /* ボックス要素に変更 */
  box-sizing     : border-box;          /* 枠線を含んだサイズ */
  height         : 60px;                /* ボタンの高さ       */
  border         : 2px solid #999999;   /* 未選択タブのの枠線 */
  border-radius  : 30px;                /* 角丸               */
}

/* === チェックボックスのラベル（ONのとき） ================ */
.switchArea input[type="checkbox"]:checked +label {
  border-color   : #78bd78;             /* 選択タブの枠線     */
}

/* === 表示する文字（標準） ================================ */
.switchArea label span:after{
  content        : "OFF";               /* 表示する文字       */
  padding        : 0 0 0 36px;          /* 表示する位置       */
  color          : #999999;             /* 文字色             */
}

/* === 表示する文字（ONのとき） ============================ */
.switchArea  input[type="checkbox"]:checked + label span:after{
  content        : "ON";                /* 表示する文字       */
  padding        : 0 36px 0 0;          /* 表示する位置       */
  color          : #78bd78;             /* 文字色             */
}

/* === 丸部分のSTYLE（標準） =============================== */
.switchArea #swImg {
  position       : absolute;            /* 親要素からの相対位置*/
  width          : 52px;                /* 丸の横幅           */
  height         : 52px;                /* 丸の高さ           */
  background     : #999999;             /* カーソルタブの背景 */
  top            : 4px;                 /* 親要素からの位置   */
  left           : 4px;                 /* 親要素からの位置   */
  border-radius  : 26px;                /* 角丸               */
  transition     : .2s;                 /* 滑らか変化         */
}

/* === 丸部分のSTYLE（ONのとき） =========================== */
.switchArea input[type="checkbox"]:checked ~ #swImg {
  transform      : translateX(90px);    /* 丸も右へ移動       */
  background     : #78bd78;             /* カーソルタブの背景 */
}
@media screen and (max-width: 640px) {
  .caution {
    width: 90%;
    margin: 0 auto;
    font-size: 3vw;
  }
  input {
    height: 7vw;
    width: 20vw;
  }
  tr {
    height: 15vw;
  }
}
</style>
