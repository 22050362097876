<template>
  <Header/>
  <div class="main-contents">
    <router-view/>
  </div>
  <Footer/>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      inputCommand: [],
      adminCommand: [38,38,40,40,37,39,37,39,66,65]
    }
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown)
    console.log("App");
    
    setTimeout(function(){
      window.scrollTo(0,1);
    }, 1);
  },
  methods: {
    onKeyDown(event) {
      if (event.keyCode == 27) {
        this.inputCommand = [];
      } else {
        this.inputCommand.push(event.keyCode);
        if (JSON.stringify(this.inputCommand) == JSON.stringify(this.adminCommand)) {
          this.inputCommand = [];
          this.$router.push("/admin");
        }
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Aboreto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kiwi+Maru&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kaisei+Decol&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap');
@import "../node_modules/bulma/css/bulma.css";
@import "../node_modules/bulma-tooltip/dist/css/bulma-tooltip.min.css";

:root {
  --th-color: aliceblue;
  --color: #2c3e50;

  --footer-bg-color: #ff6b6b;
  --footer-color: #fffbed;
  --company-color: #3b4675;
}

body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color);
}
.main-contents {
  width: 100%;
  display: inline-grid;
}
.noto-sans-jp-400 {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
@media screen and (max-width: 640px) {
  .main-contents {
    width: 100%;
  }
}
</style>
